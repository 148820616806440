/**
 * Created by mercolani on 04/04/16.
 */

import { AlbumStorage } from '../../album/utils';
import { MattedPrintsStorage } from '../../matted-prints/utils';
import { UsbDriveStorage } from '../../usb-drive/utils';
import { AlbumPackagingStorage } from '../../album-packaging/utils';
import {
  EventbookProjectStorage,
  MattedPrintsProjectStorage,
  UsbDriveProjectStorage
} from './index';
import { AlbumProjectStorage } from './index';
import { MattedPrintsPackagingStorage } from '../../matted-prints-packaging/utils';
import { UsbDrivePackagingStorage } from '../../usb-drive/utils';
import { FrameProjectStorage } from './index';

export async function cleanEventBookConfigurator() {
  await Promise.all([EventbookProjectStorage.clean()]);
}
export async function cleanAlbumConfigurator(preserveProjectName = false) {
  await Promise.all([
    AlbumStorage.clean(),
    AlbumPackagingStorage.clean(),
    AlbumProjectStorage.clean(preserveProjectName)
  ]);
}

export async function cleanMattedPrintsConfigurator(preserveProjectName = false) {
  await Promise.all([
    MattedPrintsStorage.clean(),
    MattedPrintsPackagingStorage.clean(),
    MattedPrintsProjectStorage.clean(preserveProjectName)
  ]);
}

export async function cleanUsbDriveConfigurator(preserveProjectName = false) {
  await Promise.all([
    UsbDriveStorage.clean(),
    UsbDrivePackagingStorage.clean(),
    UsbDriveProjectStorage.clean(preserveProjectName)
  ]);
}

export async function cleanFrameConfigurator(preserveProjectName = false) {
  await Promise.all([FrameProjectStorage.clean(preserveProjectName)]);
}

export async function cleanAllConfigurators() {
  await cleanAlbumConfigurator();
  await cleanEventBookConfigurator();
}
