import Logger from '../logger';

/**
 * Custom error class to handle Albumepoca exceptions
 * */
export default class {
  constructor(message, extraInfo = {}) {
    this.name = this.constructor.name;
    this.message = message;
    Error.captureStackTrace(this, this.name);
    Logger.error(this.message, Object.assign(this, extraInfo));
  }
}

export class HttpRequestError extends Error {
  constructor(...args) {
    super(...args);
    this.name = `${args[0]} HttpRequestError`;
    this.message = args[1];
    try {
      Error.captureStackTrace(this, HttpRequestError);
    } catch (e) {} //eslint-disable-line
  }
}
