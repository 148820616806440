import React from 'react';
import Select from 'react-select';

import BaseBootstrapWidget from './baseBootstrapWidget';

export default class BaseEventTypesWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: this.props.delegate.eventTypeSelected,
      errors: [],
      showErrors: false,
      value: this.props.delegate.product.productConfiguration.eventType
    };
    this.handleChange = this.handleChange.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
  }

  get name() {
    return 'eventType';
  }

  get label() {
    return this.i18n.gettext('event type');
  }

  renderOptions() {
    let renderedOptions = [];
    if (this.props.items && this.props.items.map) {
      renderedOptions = this.props.items.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
    }

    return renderedOptions;
  }

  setConfigurationItem(conf, name, id) {
    if (conf[name] !== id) {
      conf[name] = id;
      this.render(this.context);
    }
  }

  async handleChange(val) {
    if (val) {
      this.setState({
        value: val.value,
        isSelected: true
        //showErrors: true
      });
      // this.props.delegate.setConfigurationItem(this.name, val.value);
      this.setConfigurationItem(this.props.delegate.configuration, this.name, val.value);
      this.props.delegate.eventTypeSelected = true;
    } else {
      this.setState({ value: null, isSelected: false });
    }
  }

  async UNSAFE_componentWillReceiveProps() {
    let errors = [];
    // if (!this.state.isSelected) {
    //   if (!this.state.value) {
    //     errors.push({
    //       message: this.i18n.gettext('Per proseguire devi scegliere tipo di evento'),
    //       code: this.name
    //     });
    //   }
    // }
    this.setState({ errors: errors });
  }

  // renderError(error) {
  //   if (this.state.showErrors || this.props.delegate.getErrorsVisibility()) {
  //     return <div key={error.code} className="alert alert-danger text-error" role="alert">
  //       <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;
  //       <span className="sr-only">{this.i18n.gettext('Errore:')}</span>
  //       { error.message }
  //     </div>;
  //   }
  // }

  render() {
    let options = this.renderOptions();

    return (
      <div>
        <div className="block__widget">
          {this.renderLabel()}
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 padding-col-dx">
              <Select
                name="form-field-name"
                value={this.state.value}
                options={options}
                onChange={this.handleChange}
                placeholder={this.i18n.gettext('Scegli...')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
