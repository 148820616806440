import React from 'react';
import classNames from 'classnames';
import BaseBootstrapWidget from './baseBootstrapWidget';
import { sprintf } from '../sprintf';

export class BaseUvGraphicTextWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);

    this.codeElement = null;

    this.state = {
      isChosen: false,
      errors: [],
      errorsSum: 0,
      showErrors: false,
      errorsTexts: [],
      errorsDate: []
    };
  }

  isNumeric(val) {
    return !isNaN(val);
  }

  async collectErrors(item, value, index) {
    let errors = [];
    let maxLimit = item.maxTextLength;
    let minLimit = item.minTextLength;
    let isRequired = item.isRequired;

    if ((!value || value.length === 0) && isRequired) {
      this.delegate.filtersViewValidity = false;
      errors.push({
        message: this.i18n.gettext('Il testo non può essere vuoto'),
        rowCode: item.rowCode,
        code: 1
      });
    }
    // Max Limit
    if (value && value.length > maxLimit) {
      errors.push({
        message: sprintf(this.i18n.gettext('Inserire al massimo %s caratteri'), maxLimit),
        rowCode: item.rowCode,
        code: 2
      });
    }
    // Min Limit
    if (value && value.length < minLimit) {
      errors.push({
        message: sprintf(this.i18n.gettext('Inserire almeno %s caratteri'), minLimit),
        rowCode: item.rowCode,
        code: 3
      });
    }
    // Date
    if (value && item.contentType === 'NUMBER' && !this.isNumeric(value)) {
      errors.push({
        message: this.i18n.gettext('Inserire la data corretta'),
        rowCode: item.rowCode,
        code: 4
      });
    }

    if (item.contentType === 'TEXT') {
      if (index === item.rowCode) {
        this.setState({
          [`errorsTexts${index}`]: errors
        });
      }
    } else if (item.contentType === 'NUMBER') {
      if (index === item.rowCode) {
        this.setState({
          [`errorsDate${index}`]: errors
        });
      }
    }
  }

  isValid() {
    let errorsSum = 0;
    for (let key in this.state) {
      if (
        key.indexOf('errorsTexts') !== -1 ||
        (key.indexOf('errorsDate') !== -1 && this.state[key].length > 0)
      ) {
        errorsSum += this.state[key].length;
      }
    }
    return errorsSum === 0;
  }

  async checkTextErrors(items) {
    await items.map((item) => {
      let valTexts = null;
      let valDate = null;
      if (this.props.value && item.contentType === 'TEXT') {
        if (this.props.value[item.rowCode]) {
          valTexts = this.props.value[item.rowCode];
        }
        this.collectErrors(item, valTexts, item.rowCode);
      } else if (this.props.value && item.contentType === 'NUMBER') {
        if (this.props.value[item.rowCode]) {
          valDate = this.props.value[item.rowCode];
        }
        this.collectErrors(item, valDate, item.rowCode);
      }
    });
    this.propagateErrors();
  }

  async removeOLdErrors(items) {
    if (items && items.map) {
      await items.map((item) => {
        if (item.contentType === 'TEXT') {
          this.setState({
            [`errorsTexts${item.rowCode}`]: []
          });
        } else if (item.contentType === 'NUMBER') {
          this.setState({
            [`errorsDate${item.rowCode}`]: []
          });
        }
      });
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    if (
      nextProps.items &&
      this.props.items &&
      this.props.items.length !== 0 &&
      nextProps.items.length === 0
    ) {
      /*
       * Some actions when widget is deactivated
       */
    }
    if (nextProps.items && nextProps.items.map && nextProps.items !== this.props.items) {
      this.removeOLdErrors(this.props.items);
      this.checkTextErrors(nextProps.items);
    }
    /*il widget aveva errori ma è stato disattivato, devo rimuoverli tutti*/
    if (!this.isValid() && (!this.props.items || this.props.items.length === 0)) {
      for (let key in this.state) {
        if (
          key.indexOf('errorsTexts') !== -1 ||
          (key.indexOf('errorsDate') !== -1 && this.state[key].length > 0)
        ) {
          await this.setState({
            [key]: []
          });
        }
      }
      this.props.popWidgetError(this.name);
    }
  }

  async handleChangeText(item, index, event) {
    //need to handle date errors
    this.decrementZindex(item);

    this.setState({
      showErrors: true
    });
    let string = event.target.value;
    if (item.contentType === 'NUMBER' && !/^(\s*|\d+)$/.test(string)) {
      return;
    } else {
      if (!/[^\u0000-\u00ff]/g.test(string)) { //eslint-disable-line
        await this.props.delegate.setConfigurationTexts(this.name, index, string);
        await this.collectErrors(item, string, item.rowCode);
      }
    }
    this.propagateErrors();
    this.props.delegate.setPreviewSvg();
  }

  handleKeyPress(event) {
    if (event.which === 13 && this.codeElement) {
      this.codeElement.focus();
    }
  }

  handleFocus() {
    return null;
  }

  getTextItems() {
    let items = [];
    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        if (item.fieldType === 'INLINE') {
          return;
        }
        return (
          <div key={item.id} className="row">
            {this.renderBlockElements(item)}
          </div>
        );
      });
    }
    return items;
  }

  getDateItems() {
    let items = [];
    let dateItems = [];
    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        if (item.contentType === 'NUMBER') {
          return <div key={item.id}>{this.renderInlineElements(item)}</div>;
        }
      });
    }
    for (let i = 0; i < items.length; i++) {
      if (items[i]) {
        dateItems.push(items[i]);
      }
    }
    return dateItems;
  }

  renderDateErrors() {
    let errors = [];
    if (this.props.items && this.props.items.map) {
      errors = this.props.items.map((item) => {
        if (item.fieldType === 'BLOCK') {
          return;
        }
        return (
          <div key={`error${item.id}`} className="padding-col-dx date-errors">
            {this.state[`errorsDate${item.rowCode}`].map((error) => {
              let errorHolder =
                item.rowCode === error.rowCode ? this.renderError(error, item) : null;
              return errorHolder;
            })}
          </div>
        );
      });
    }
    return errors;
  }

  renderBlockElements(item) {
    let placeholder = item.name;
    if (item.minTextLength === 0) {
      placeholder = `${item.name} ${this.i18n.gettext('(opzionale)')}`;
    }
    let text = '';

    if (this.props.value && this.props.value[item.rowCode]) {
      text = this.props.value[item.rowCode];
    }

    return (
      <div className="col-xs-12 col-sm-12 col-md-12 padding-col-dx">
        <input
          type="text"
          className="form-control text__targa"
          placeholder={placeholder}
          value={text}
          onChange={this.handleChangeText.bind(this, item, item.rowCode)}
          onKeyPressCapture={this.handleKeyPress.bind(this)}
          onFocus={this.handleFocus.bind(this, item)}
        />
        <div>
          {this.state[`errorsTexts${item.rowCode}`].map((error) => {
            let errorHolder = item.rowCode === error.rowCode ? this.renderError(error) : null;
            return errorHolder;
          })}
        </div>
      </div>
    );
  }

  renderInlineElements(item) {
    let columnClass = classNames(
      'col-xs-3',
      'col-sm-3',
      'col-md-3',
      'padding-col-dx',
      'col-half-offset'
    );

    let placeholder;

    if (item.name.toLowerCase() === 'day') {
      placeholder = '01';
    } else if (item.name.toLowerCase() === 'month') {
      placeholder = '01';
    } else if (item.name.toLowerCase() === 'year') {
      placeholder = new Date().getFullYear().toString().substr(-2);
    }

    if (!item.isRequired) {
      placeholder = `${placeholder} ${this.i18n.gettext('(opzionale)')}`;
    }
    let text = '';
    if (this.props.value && this.props.value[item.rowCode]) {
      text = this.props.value[item.rowCode];
    }

    return (
      <div className={columnClass}>
        <input
          type="text"
          className="form-control text__targa"
          placeholder={placeholder}
          value={text}
          onChange={this.handleChangeText.bind(this, item, item.rowCode)}
          onKeyPressCapture={this.handleKeyPress.bind(this)}
          onFocus={this.handleFocus.bind(this, item)}
        />
      </div>
    );
  }

  decrementZindex(item) {
    if (
      item.contentType === 'NUMBER' &&
      this.currentErrorElement &&
      this.currentErrorcode !== item.rowCode
    ) {
      this.currentErrorElement.style.zIndex = 1;
    }
  }

  renderError(error, item) {
    let index = 2;

    let currentErrorStyle =
      item && item.contentType === 'NUMBER' && item.rowCode === error.rowCode
        ? { zIndex: index }
        : null;

    if (this.state.showErrors || this.props.delegate.getErrorsVisibility()) {
      return (
        <div
          key={error.code}
          className="alert alert-danger text-error"
          ref={(currentError) => {
            this.currentErrorElement = currentError;
            this.currentErrorcode = error.rowCode;
          }}
          style={currentErrorStyle}
          role="alert">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;
          <span className="sr-only">{this.i18n.gettext('Errore:')}</span>
          {error.message}
        </div>
      );
    }
  }

  getDateErrorsLength() {
    this.dateErrorsLength = 0;
    for (let key in this.state) {
      if (key.indexOf('errorsDate') !== -1 && this.state[key].length > 0) {
        this.dateErrorsLength = this.state[key].length;
      }
    }
    return this.dateErrorsLength;
  }

  renderContext() {
    let label = this.getDateItems().length > 0 ? this.i18n.gettext("Data dell'evento") : null;
    let errorsVisible =
      this.state.showErrors && this.getDateErrorsLength() > 0 ? '' : 'errors-hidden';
    return (
      <div className="product__formats" data-product-formats="">
        {this.getTextItems()}
        <div className="row">
          <label className="padding-col-dx">{label}</label>
        </div>
        <div className="row date-items">{this.getDateItems()}</div>
        <div className={`row error-date-items ${errorsVisible}`}>{this.renderDateErrors()}</div>
      </div>
    );
  }
}
