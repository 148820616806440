import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { components } from 'outlinejs';

import { AeModal } from '@photosi/albumepoca-ui';

export default class BaseBootstrapWidget extends components.BaseComponent {
  constructor(props) {
    super(props);
    this._loadingClass = 'loading-component';
    this._activeClass = 'active';
    this._selectedItemKey = 'id';
    this._calculatePrice = true;
    this._showWidget = true;

    this._scrollToWidgetIsActive = false;
    this._clickedItem = null;

    this._hasConfirmModal = false;
    this._confirmModalRef = `${this.name}-confirm-modal`;
    this.modalConfirmClick = this.modalConfirmClick.bind(this);
    this.onModalConfirmClick = this.onModalConfirmClick.bind(this);

    this.widgetElement = null;

    this.state = {
      isChosen: false,
      errors: [],
      showErrors: false,
      showModal: false,
      dynamicText: false
    };
  }

  get label() {
    return this.i18n.gettext('widget anonimo');
  }

  get loadingClassActive() {
    return true;
  }

  get loadingClass() {
    // return this._loadingClass;
    return this.loadingClassActive && this.loading
      ? `col-sm-12 ${this._loadingClass}`
      : 'col-sm-12';
  }

  set loadingClass(value) {
    this._loadingClass = value;
  }

  get activeClass() {
    return this._activeClass;
  }

  set activeClass(value) {
    this._activeClass = value;
  }

  get scrollToWidgetIsActive() {
    return this._scrollToWidgetIsActive;
  }

  set scrollToWidgetIsActive(value) {
    this._scrollToWidgetIsActive = value;
  }

  get calculatePrice() {
    return this._calculatePrice;
  }

  set calculatePrice(value) {
    this._calculatePrice = value;
  }

  get showWidget() {
    return this._showWidget;
  }

  set showWidget(value) {
    this._showWidget = value;
  }

  get loading() {
    return this.props.delegate.filtersViewIsLoading;
  }

  get items() {
    return this.props.items;
  }

  get selected() {
    return this.props.selected;
  }

  get selectedItemKey() {
    return this._selectedItemKey;
  }

  set selectedItemKey(value) {
    this._selectedItemKey = value;
  }

  get clickedItem() {
    return this._clickedItem;
  }

  set clickedItem(item) {
    this._clickedItem = item;
  }

  get hasConfirmModal() {
    return this._hasConfirmModal;
  }

  set hasConfirmModal(value) {
    this._hasConfirmModal = value;
  }

  get confirmModalRef() {
    return this._confirmModalRef;
  }

  set confirmModalRef(value) {
    this._confirmModalRef = value;
  }

  get confirmModalMessage() {
    return this.i18n.gettext(
      "Attenzione: modificando questa configurazione perderai l'attuale personalizzazione della copertina. Confermi la modifica?"
    );
  }

  disable() {
    this.setState({ disabled: true });
  }

  enable() {
    this.setState({ disabled: false });
  }

  handleClick(item) {
    this.clickedItem = item;
    if (this.shouldComponentShowConfirmModal()) {
      this.showConfirmModal();
    } else {
      this.propagateClick();
    }
  }

  propagateClick() {
    if (this.clickedItem) {
      this.setState({ isChosen: true });
      this.props.delegate.setConfigurationItem(
        this.name,
        this.clickedItem.id,
        null,
        this.calculatePrice
      );
    }
  }

  isSelected(item) {
    return item[this.selectedItemKey] === this.selected;
  }

  getSelectedItemClass(item) {
    return this.isSelected(item) ? this.activeClass : '';
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.items &&
      nextProps.items.length &&
      (!nextProps.selected ||
        this.props.selected !== nextProps.selected ||
        this.props.items !== nextProps.items)
    ) {
      this.collectErrors(nextProps);
    }
    let disabledWidgets = await this.props.delegate.getdisabledWidgets();
    if (disabledWidgets.indexOf(this.name) >= 0) {
      this.disable();
    } else {
      this.enable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.errors.length !== this.state.errors.length) {
      this.propagateErrors();
    }
  }

  collectErrors(props) {
    let errors = [];
    let validateSelected = this.validateSelectedNotAvailableInItemSet(props);
    if (validateSelected) {
      errors.push(validateSelected);
    }
    /*more errors check goes there*/
    this.setState({ errors: errors });
  }

  isValid() {
    return this.state.errors.length === 0;
  }

  propagateErrors() {
    let validWidget = this.isValid();
    if (validWidget) {
      this.props.popWidgetError(this.name);
    } else {
      this.props.pushWidgetError(this.name);
    }
  }

  validateSelectedNotAvailableInItemSet(props) {
    let isSelected = false;
    let selected = props.selected;
    let error = null;
    props.items.each(function (item, $selected, $isSelected) {  //eslint-disable-line
      isSelected = isSelected || item.id === selected;
    });

    if (!isSelected) {
      error = {
        message: this.i18n.gettext(
          'Le opzioni selezionate non portano ad una configurazione valida'
        ),
        code: null
      };
    }
    return error;
  }

  renderErrors() {
    let errors = <div></div>;
    if (this.state.showErrors || this.props.delegate.getErrorsVisibility()) {
      errors = (
        <div className="row">
          {this.state.errors.map((error) => {
            let idErrorCode = error.code || this.name;

            return (
              <div key={error.code} id={idErrorCode} className="alert alert-danger" role="alert">
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;
                <span className="sr-only">{this.i18n.gettext('Errore:')}</span>
                {error.message}
              </div>
            );
          })}
        </div>
      );
    }
    return errors;
  }

  visibleCLass() {
    return classNames(
      'block__widget',
      { disabled: this.state.disabled },
      { hide: !this.props.items || this.props.items.length === 0 || !this.showWidget }
    );
  }

  renderLabel() {
    return (
      <div className="row">
        <div className="product__option__subtitle">{this.label}</div>
      </div>
    );
  }

  /*  MODAL FUNCTIONS  */
  shouldComponentShowConfirmModal() {
    return false;
  }

  showConfirmModal() {
    this.setState({
      showModal: true,
      dynamicText: true
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
      dynamicText: false
    });
  }

  // override this function to handle confirm button
  async onModalConfirmClick(e) { //eslint-disable-line
    try {
      throw new Error('onModalConfirmClick must be implemented');
    } catch (err) {
      return true;
    }
  }

  async modalConfirmClick(e) {
    this.propagateClick();
    await this.onModalConfirmClick(e);
    this.setState({
      showModal: false,
      dynamicText: false
    });
  }

  renderConfirmModalBody() {
    return <div className="modal-content-body">{this.confirmModalMessage}</div>;
  }

  renderConfirmModal(msg = null) {
    const { showModal, dynamicText } = this.state;
    let title = this.i18n.gettext('Attenzione');
    let modalClasses = {
      'modal-lg': true,
      'modal-copertina': true
    };

    let message = msg
      ? msg
      : this.i18n.gettext(
          "Attenzione: modificando questa configurazione perderai l'attuale personalizzazione della copertina. Confermi la modifica?"
        );

    let dynamicContent = (
      <div>
        <div className="row">
          <div className="col-sm-12 modal-content-body">{message}</div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6 text-center">
            <a className="basic__cta__btns" onClick={this.modalConfirmClick.bind(this)}>
              {this.i18n.gettext('conferma')}
            </a>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 text-center">
            <a onClick={this.closeModal.bind(this)} className="basic__cta__btns">
              {this.i18n.gettext('annulla')}
            </a>
          </div>
        </div>
      </div>
    );
    if (!this.hasConfirmModal) {
      return <div></div>;
    }
    return (
      <AeModal
        isOpen={showModal}
        title={title}
        dynamicText={dynamicText}
        dynamicTextContent={dynamicContent}
        customClasses={modalClasses}
        onClose={this.closeModal.bind(this)}
      />
    );
  }

  renderContext() {
    try {
      throw new Error('renderContext must be implemented');
    } catch (err) {
      return {};
    }
  }

  render() {
    return (
      <div
        className={this.visibleCLass()}
        ref={(div) => {
          this.widgetElement = div;
        }}>
        <div className="row">
          <div className="col-sm-12">{this.renderLabel()}</div>
          <div className={this.loadingClass}>{this.renderContext()}</div>
        </div>
        {this.renderConfirmModal()}
      </div>
    );
  }
}

BaseBootstrapWidget.propTypes = {
  delegate: PropTypes.object,
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string
};
