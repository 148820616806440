import React from 'react';
import classNames from 'classnames';

import BaseBootstrapWidget from './baseBootstrapWidget';

export class BaseSelectionWidgetWithImages extends BaseBootstrapWidget {
  renderContext() {
    let items = [];
    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        let itemClass = classNames('btn-ae-img', { active: item.id === this.props.selected });

        let imageUrl = item.fileUrl;

        return (
          <div
            className="row"
            key={item.id}
            onClick={this.handleClick.bind(this, item)}
            data-action={`set_${this.props.items.name}_${item.id}`}>
            <div className="col-xs-12 col-sm-12 col-md-12 no-padding-col">
              <div className={itemClass}>
                <div className="row">
                  <div className="col-xs-3 col-sm-3 col-md-6 option__thumb">
                    {imageUrl ? <img src={imageUrl} /> : null}
                  </div>
                  <div className="col-xs-9 col-sm-9 col-md-6 no-padding-col">
                    <div className="option__title">{item.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="product__formats" data-product-formats="">
        {items}
      </div>
    );
  }
}
