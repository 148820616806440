import React from 'react';
import classNames from 'classnames';
import { AeMarkupText } from '@photosi/albumepoca-ui';

import { BaseSelectionWidgetTwoColumns } from './baseSelectionWidgetTwoColumns';
import { AeImage } from './AeImage';

export class GraphicCollapsableWidget extends BaseSelectionWidgetTwoColumns {
  itemClass(item) {
    return classNames('switchers-images__item', { active: item.id === this.props.selected });
  }

  handleOpen() {
    this.setState({ isChosen: false });
  }

  handleMouseOver(item) {//eslint-disable-line
    return null;
  }

  handleMouseOut() {
    return null;
  }

  handleClick(item) {
    super.handleClick(item);
    if (this.scrollToWidgetIsActive) {
      this.props.delegate.scrollToWidget(this.widgetElement);
    }
  }

  renderItems() {
    let renderedItems = [];
    if (this.props.items && this.props.items.length > 0) {
      renderedItems = this.props.items.map((item) => {
        return (
          <div
            key={item.id}
            className="col-xs-6 col-sm-4 col-md-6 padding-color"
            onMouseEnter={this.handleMouseOver.bind(this, item)}>
            <a onClick={this.handleClick.bind(this, item)} className={this.itemClass(item)}>
              <span className="switchers-images__item__image">
                <AeImage src={item.fileUrl} />
              </span>
            </a>
          </div>
        );
      });
    }
    return renderedItems;
  }

  /*render only the selected item if available*/
  renderSelectedItem() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        if (item.id === this.props.selected) {
          return (
            <div key={item.id} className="col-xs-6 col-sm-4 col-md-6 padding-color vcenter">
              <a className={this.itemClass(item)}>
                <span className="switchers-images__item__image">
                  <AeImage src={item.fileUrl} />
                </span>
              </a>
            </div>
          );
        }
      });
    }
    return renderedItems;
  }

  renderArrow() {
    return (
      <div className="col-xs-6 col-sm-2 col-md-2 text-right no-padding-col vcenter">
        <div className="arrowdown"></div>
      </div>
    );
  }

  /*render the collapsed version of the widget*/
  renderSummary() {
    return (
      <div className="product__formats">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 no-padding-col">
            <div
              className="btn-ae"
              onClick={this.handleOpen.bind(this)}
              data-action={`set_${this.props.items.name}_selected_${this.props.selected}`}>
              {this.renderSelectedItem()}
              {this.renderArrow()}
              <div className="clear"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /*render the full length version of the widget*/
  renderFull() {
    return (
      <div className="product__formats">
        <div className="row">
          <div className="padding-color">
            <AeMarkupText text={this.textDescription} />
          </div>
        </div>
        <div className="row" onMouseLeave={this.handleMouseOut.bind(this)}>
          {this.renderItems()}
        </div>
        {this.renderErrors()}
      </div>
    );
  }

  renderContext() {
    return (
      <div>
        {this.state.isChosen && this.props.items !== undefined && this.props.items.length !== 1
          ? this.renderSummary()
          : this.renderFull()}
      </div>
    );
  }
}
