import Logger from '../core/logger';

/*
 * Base Class handler for Album Cover Editor Project
 * */
export default class CoverEditorBaseController {
  constructor(requestContext, productConfigurationModel) {
    this.request = requestContext;

    this.coverEditorProjectModel = null;
    this.productConfigurationModel = productConfigurationModel;

    this._applicationBaseUrl = null;
    this._coverEditorProjectClass = null;
  }

  get coverEditorProjectClass() {
    return this._coverEditorProjectClass;
  }

  set coverEditorProjectClass(value) {
    this._coverEditorProjectClass = value;
  }

  get applicationBaseUrl() {
    return this._applicationBaseUrl;
  }

  set applicationBaseUrl(value) {
    this._applicationBaseUrl = value;
  }

  get applicationUrl() {
    return `${this.applicationBaseUrl}?projectId=${this.coverEditorProjectModel.id}&iframe`;
  }

  get productConfigurationId() {
    return this.productConfigurationModel.id;
  }

  get productConfigurationType() {
    return this.productConfigurationModel.productType;
  }

  async getCoverEditorProject(id) {
    try {
      return await new this.coverEditorProjectClass({id: id}).fetch(); //eslint-disable-line
    } catch (err) {
      Logger.error(`getCoverEditorProject for ${this.applicationBaseUrl}`, {
        error: err,
        coverEditorProjectModel: this.coverEditorProjectModel,
        productConfigurationModel: this.productConfigurationModel
      });
    }
  }

  async createCoverEditorProject() {
    let newProjectData = {
      shopCode: this.request.customerUser.shopCode,
      language: this.request.language,
      productConfigurationId: this.productConfigurationId,
      productConfigurationType: this.productConfigurationType
    };
    this.coverEditorProjectModel = await new this.coverEditorProjectClass(newProjectData).save(); //eslint-disable-line

    return this.coverEditorProjectModel;
  }

  async resetCoverEditorProject() {
    Logger.info('resetCoverEditorProject');
    this.coverEditorProjectModel = await this.coverEditorProjectModel.fetch();
    return this.coverEditorProjectModel;
  }

  async syncCoverEditorProject() {
    this.coverEditorProjectModel = await this.coverEditorProjectModel.fetch();
  }

  async getCoverEditorApplicationUrl() {
    let applicationUrl = null;
    try {
      applicationUrl = this.applicationUrl;
    } catch (err) {
      Logger.error('getCoverEditorApplicationUrl', {
        error: err,
        applicationBaseUrl: this.applicationBaseUrl,
        coverEditorProjectModel: this.coverEditorProjectModel,
        productConfigurationModel: this.productConfigurationModel
      });

      // retry after syncing model
      await this.syncCoverEditorProject();
      applicationUrl = this.applicationUrl;
    }

    return applicationUrl;
  }
}
