import React from 'react';

import BaseBootstrapWidget from '../../core/components/baseBootstrapWidget';
//import {AeMarkupText} from '@photosi/albumepoca-ui';

export class BaseBootstrapWithDescriptionWidget extends BaseBootstrapWidget {
  get name() {
    throw new Error('name must be defined');
  }

  get label() {
    throw new Error('label must be defined');
  }

  get calculatePrice() {
    return false;
  }

  get descriptionSubTitle() {
    throw new Error('descriptionSubTitle must be defined');
  }

  get deprecatedDescription() {
    throw new Error('deprecatedDescription must be defined');
  }

  textDescription() {
    return (
      <p>
        {this.descriptionSubTitle}
        <br />
        <strong>Giotto</strong>, {this.i18n.gettext('Giotto material description')}
        <br />
        <strong>Michelangelo</strong>, {this.i18n.gettext('Michelangelo material description')}
        <br />
        <strong>Modigliani</strong>, {this.i18n.gettext('Modigliani material description')}
        <br />
        <strong>Tiziano</strong>, {this.i18n.gettext('Tiziano material description')}
        <br />
        <strong>Caravaggio</strong>, {this.i18n.gettext('Caravaggio material description')}
        <br />
        <strong>Leonardo</strong>, {this.i18n.gettext('Leonardo material description')}
        <br />
        <strong>Masaccio</strong>, {this.i18n.gettext('Masaccio material description')}
        <br />
        <strong>Raffaello</strong>, {this.i18n.gettext('Raffaello material description')}
        <br />
        <strong>Tintoretto</strong>, {this.i18n.gettext('Tintoretto material description')}
        <br />
      </p>
    );
  }

  renderContext() {
    return (
      <div className="product__formats" data-product-formats="">
        <div className="row">
          <div className="padding-color">{this.textDescription()}</div>
        </div>
        <div className="row">{this.renderItems()}</div>
        {this.renderErrors()}
      </div>
    );
  }
}
