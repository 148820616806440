import React from 'react';
import { AeImage } from './AeImage';
import { GraphicCollapsableWidgetWithText } from './graphicCollapsableWidgetWithText';
import { conf } from 'outlinejs';

export class BaseDebossingElementColorsWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    throw new Error('name must be defined');
  }

  get label() {
    throw new Error('label must be defined');
  }

  get calculatePrice() {
    return false;
  }

  get configurationDebossingElementColorType() {
    throw new Error('configurationDebossingElementColorType must be defined in configuration');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items && this.props.selected !== nextProps.selected) {
      this.setState({ isChosen: false }); //need to reopen the item selector
    }

    //ned to set the new svg color
    if (nextProps.items && this.props.items !== nextProps.items) {
      if (nextProps.items.length === 0) {
        this.props.delegate.resetPreviewDebossingSvgColor();
      }

      let setColor;
      nextProps.items.each((item) => {
        if (this.configurationDebossingElementColorType) {
          setColor = nextProps.selected && item.id === nextProps.selected;
        } else {
          setColor = item.isDefault;
        }
        if (setColor) {
          this.props.delegate.choseDebossingPreviewSvgColor(
            item.colorCode,
            item.isMetallicColor,
            item.id
          );
          this.props.delegate.setDebossingPreviewSvgColor(
            item.colorCode,
            item.isMetallicColor,
            item.id
          );
          this.props.delegate.setDebossingPreviewSvg();
        }
      });
    }
  }

  handleMouseOver(item) {
    this.props.delegate.setDebossingPreviewSvgColor(item.colorCode, item.isMetallicColor, item.id);
    this.props.delegate.setDebossingPreviewSvg();
  }

  handleMouseOut() {
    this.props.delegate.setDebossingPreviewSvgColor();
    this.props.delegate.setDebossingPreviewSvg();
  }

  handleClick(item) {
    super.handleClick(item);
    //this.props.delegate.packagingConfiguration.uvGraphicColorSelected = true;
    this.props.delegate.choseDebossingPreviewSvgColor(
      item.colorCode,
      item.isMetallicColor,
      item.id
    );
    this.props.delegate.setDebossingPreviewSvgColor(item.colorCode, item.isMetallicColor, item.id);
  }

  /*render all items*/
  renderItems() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        return (
          <div
            key={item.id}
            className="col-xs-6 col-sm-4 col-md-4 padding-color"
            onMouseEnter={this.handleMouseOver.bind(this, item)}
            onMouseLeave={this.handleMouseOut.bind(this)}>
            <a
              onClick={this.handleClick.bind(this, item)}
              className={this.itemClass(item)}
              data-action={`set_${this.props.items.name}_${item.id}`}>
              <span
                className="colors__item__image"
                style={{
                  backgroundImage: item.isMetallicColor
                    ? conf.settings.METALLIC_COLORS[item.id]
                    : 'none',
                  backgroundColor: item.isMetallicColor ? 'none' : item.colorCode
                }}>
                <AeImage src={item.fileUrl} />
              </span>
              <span className="colors__item__text">
                {item.name}
                <br />
                {item.description}
              </span>
            </a>
          </div>
        );
      });
    }
    return renderedItems;
  }

  renderContext() {
    return <div>{this.renderFull()}</div>;
  }
}
