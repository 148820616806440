import React from 'react';
import { components } from 'outlinejs';

export class FiltersTitle extends components.BaseComponent {
  render() {
    return (
      <div>
        <h1 className="product__option__title">{this.props.title}</h1>
      </div>
    );
  }
}
