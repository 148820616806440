import React from 'react';

import BaseBootstrapWidget from './baseBootstrapWidget';
import { sprintf } from '../../core/sprintf';

export class BaseTextWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);

    this.codeRow2Element = null;

    this.state = {
      isChosen: false,
      errors: [],
      showErrors: false,
      errorsText1: [],
      errorsText2: []
    };
  }

  get loadingClassActive() {
    return false;
  }

  async collectErrors(item, value, index) {
    let errors = [];
    let limit = item['charLimitRow' + index];
    let isRequired = item['isRequiredRow' + index];

    if ((!value || value.length === 0) && isRequired) {
      errors.push({
        message: this.i18n.gettext('Il testo non può essere vuoto'),
        code: index
      });
    }
    // Limit
    if (limit && value && value.length > limit) {
      errors.push({
        message: sprintf(
          this.i18n.gettext('Inserire %s caratteri o meno nella riga con indice %s'),
          limit,
          index
        ),
        code: index
      });
    }
    if (index === 1) {
      this.setState({
        errorsText1: errors
      });
    } else if (index === 2) {
      //else if (index === 2 && item.isRequiredRow2) {
      this.setState({
        errorsText2: errors
      });
    }
  }

  isValid() {
    return this.state.errorsText1.length + this.state.errorsText2.length === 0;
  }

  async checkTextErrors(items) {
    await items.map((item) => {
      let val1 = '';
      let val2 = '';
      if (this.props.value) {
        val1 = this.props.value[1];
        val2 = this.props.value[2];
      }
      this.collectErrors(item, val1, 1);
      this.collectErrors(item, val2, 2);
    });
    this.propagateErrors();
  }

  async componentDidMount() {
    if (this.props.items && this.props.items.map) {
      this.checkTextErrors(this.props.items);
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    if (
      nextProps.items &&
      this.props.items &&
      this.props.items.length !== 0 &&
      nextProps.items.length === 0
    ) {
      this.props.delegate.resetConfigurationTexts(this.name);
    }
    if (nextProps.items && nextProps.items.map && nextProps.items !== this.props.items) {
      this.checkTextErrors(nextProps.items);
    }
    /*il widget aveva errori ma è stato disattivato, devo rimuoverli tutti*/
    if (!this.isValid() && (!this.props.items || this.props.items.length === 0)) {
      await this.setState({
        errorsText1: [],
        errorsText2: []
      });
      this.props.popWidgetError(this.name);
    }
  }

  getUserCharLimit() {
    let limit = 100;
    if (
      this.props &&
      this.props.items &&
      this.props.items.first() &&
      this.props.items.first().charLimitRow1
    ) {
      limit = this.props.items.first().charLimitRow1;
    }
    return limit;
  }

  async handleChangeText(item, index, event) {
    this.setState({
      showErrors: true
    });
    let string = event.target.value;
    if (!/[^\u0000-\u00ff]/g.test(string)) { //eslint-disable-line
      this.props.delegate.setConfigurationTexts(this.name, item['codeRow' + index], string);
      await this.collectErrors(item, string, index);
    }
    this.propagateErrors();
  }

  handleKeyPress(event) {
    if (event.which === 13 && this.codeRow2Element) {
      this.codeRow2Element.focus();
    }
  }

  handleFocus() {
    return null;
  }

  getItems() {
    let items = [];
    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        return (
          <div key={item.id} className="row">
            {this.renderRow1(item)}
            {this.renderRow2(item)}
          </div>
        );
      });
    }
    return items;
  }

  renderRow1(item) {
    let placeholder = item.nameRow1;
    if (!item.isRequiredRow1) {
      placeholder = `${item.nameRow1} ${this.i18n.gettext('(opzionale)')}`;
    }
    let text = '';
    if (this.props.value && this.props.value[1]) {
      text = this.props.value[1];
    }
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 padding-col-dx">
        <input
          type="text"
          className="form-control text__targa"
          placeholder={placeholder}
          value={text}
          onChange={this.handleChangeText.bind(this, item, 1)}
          onKeyPressCapture={this.handleKeyPress.bind(this)}
          onFocus={this.handleFocus.bind(this, item)}
        />
        <div>
          {this.state.errorsText1.map((error) => {
            return this.renderError(error);
          })}
        </div>
      </div>
    );
  }

  renderRow2(item) {
    let placeholder = item.nameRow2;
    if (!item.isRequiredRow2) {
      placeholder = `${item.nameRow2} ${this.i18n.gettext('(opzionale)')}`;
    }
    if (item.nameRow2) {
      let text = '';
      if (this.props.value && this.props.value[2]) {
        text = this.props.value[2];
      }
      return (
        <div className="col-xs-12 col-sm-12 col-md-12 padding-col-dx">
          <input
            type="text"
            className="form-control text__targa"
            ref={(input) => {
              this.codeRow2Element = input;
            }}
            placeholder={placeholder}
            value={text}
            onChange={this.handleChangeText.bind(this, item, 2)}
            onFocus={this.handleFocus.bind(this, item)}
          />
          <div>
            {this.state.errorsText2.map((error) => {
              return this.renderError(error);
            })}
          </div>
        </div>
      );
    }
  }

  renderError(error) {
    if (this.state.showErrors || this.props.delegate.getErrorsVisibility()) {
      return (
        <div key={error.code} className="alert alert-danger text-error" role="alert">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;
          <span className="sr-only">{this.i18n.gettext('Errore:')}</span>
          {error.message}
        </div>
      );
    }
  }

  renderContext() {
    return (
      <div className="product__formats" data-product-formats="">
        {this.getItems()}
      </div>
    );
  }
}
