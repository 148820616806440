import React from 'react';
import { AeImage } from './AeImage';

import { BaseDebossingElementColorsWidget } from './baseDebossingElementColorsWidget';
import { conf } from 'outlinejs';

export class BaseDebossingElementSuggestedColorsWidget extends BaseDebossingElementColorsWidget {
  get name() {
    return 'debossingElementColorType';
  }

  get label() {
    return this.i18n.gettext('debossing suggested colors');
  }

  /*render suggested items*/
  renderItems() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        if (item.isSuggested) {
          return (
            <div
              key={item.id}
              className="col-xs-6 col-sm-4 col-md-4 padding-color"
              onMouseOver={this.handleMouseOver.bind(this, item)}
              onMouseOut={this.handleMouseOut.bind(this)}>
              <a
                onClick={this.handleClick.bind(this, item)}
                className={this.itemClass(item)}
                data-action={`set_${this.props.items.name}_${item.id}`}>
                <span
                  className="colors__item__image"
                  style={{
                    backgroundImage: item.isMetallicColor
                      ? conf.settings.METALLIC_COLORS[item.id]
                      : 'none',
                    backgroundColor: item.isMetallicColor ? 'none' : item.colorCode
                  }}>
                  <AeImage src={item.fileUrl} />
                </span>
                <span className="colors__item__text">
                  {item.name}
                  <br />
                  {item.description}
                </span>
              </a>
            </div>
          );
        }
      });
    }
    return renderedItems;
  }
}
