import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/*
 * Renders Material event book.
 * Shows material and cover uv selected.
 * */
export class MaterialPreview extends React.Component {
  get imageUrl() {
    let imageUrl = this.props.product.previewUrl
      ? this.props.product.previewUrl
      : this.props.product.productItem.frontAssetUrl;
    return imageUrl
      ? imageUrl + '?crop=15,15,85,85&cropxunits=100&cropyunits=100&width=800&height=800'
      : null;
  }

  createSvg() {
    return {
      __html: this.props.product.previewSvg
        ? this.props.product.previewSvg
        : this.props.product.previewDebossingSvg
    };
  }

  render() {
    let imageUrl = this.imageUrl;

    let loadingClass = classNames('img-container', { 'loading-widget': !imageUrl });

    let height = null;
    let width = null;
    if (this.props.height) {
      height = this.props.height + 'px';
    }
    if (this.props.width) {
      width = this.props.width + 'px';
    }

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className={loadingClass} style={{ height, width }}>
            {imageUrl ? <img className="img-responsive" src={imageUrl} /> : imageUrl}
          </div>
          <div
            className="svg-container"
            dangerouslySetInnerHTML={this.createSvg()}
            style={{ height, width }}></div>
        </div>
      </div>
    );
  }
}

MaterialPreview.propTypes = {
  product: PropTypes.object
};
