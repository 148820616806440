import React from 'react';
import classNames from 'classnames';

import { components } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import { mergeQueryParams, navigateTo } from '../utils';

export class BreadcrumbComponent extends components.BaseComponent {
  activeClass(step) {
    let actualStep = this.props.delegate.funnelStep || 1000;
    return classNames({ active: step <= actualStep, current: step === actualStep });
  }

  navigateBreadcrumb(step, nextState) {
    let actualStep = this.props.delegate.funnelStep || 1000;

    if (this.props.delegate.beforeNavigateBreadcrumbFunction) {
      this.props.delegate.beforeNavigateBreadcrumbFunction();
    }

    if (runtime.isClient && nextState && step <= actualStep) {
      let params = {};
      // todo questo controllo non serve...
      if (this.props.delegate.configuration) {
        params = {
          configurationId: this.request.query.configurationId,
          packagingConfigurationId: this.request.query.packagingConfigurationId
        };
      }
      //this.response.navigate(nextState, params);
      navigateTo(
        this.request,
        this.response,
        nextState,
        {},
        false,
        mergeQueryParams(this.request.query, params)
      );
    }
  }

  render() {
    const breadcrumbClass = classNames('cartnav', { isHidden: this.props.isDisabled });

    return (
      <div className={breadcrumbClass}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <ol className="breadcrumb">
                {this.props.delegate.funnelSteps.map((step) => (
                  <li className={this.activeClass(step.step)} key={step.step}>
                    <a onClick={this.navigateBreadcrumb.bind(this, step.step, step.route)}>
                      {step.text}
                    </a>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
