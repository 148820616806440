import React from 'react';

import { sprintf } from '../../core/sprintf';
import { AeMarkupText } from '@photosi/albumepoca-ui';
import BaseBootstrapWidget from './baseBootstrapWidget';
import { debounce } from 'throttle-debounce';

export class BaseSheetsNumberWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);
    this.state = {
      value: props.selected,
      isChosen: false,
      errors: [],
      showErrors: true
    };
    this.tempvalue = props.selected;
    this.handleChange = this.handleChange.bind(this);
    this.doChange = debounce(1000, this.doChange);
  }

  get name() {
    return 'sheetsNumber';
  }

  get label() {
    return this.i18n.gettext('numero fogli');
  }

  get hasConfirmModal() {
    return true;
  }

  getHelperText() {
    return sprintf(
      this.i18n.gettext('Minimo %s, massimo %s fogli. (1 foglio = 1 file = 2 pagine).'),
      this.props.items.min,
      this.props.items.max
    );
  }

  validateMinMaxValue(value, props) {
    let min = this.items.min || 0;
    let max = this.items.max || 100;
    if (props) {
      min = props.items.min;
      max = props.items.max;
    }
    let error = [];
    if (value < min || value > max) {
      error.push({
        message: this.i18n.gettext(
          'Inserisci un numero di pagine compreso tra il minimo ed il massimo disponibile'
        ),
        code: null
      });
    }
    return error;
  }

  collectErrors(value, props) {
    var errors = [];
    if (isNaN(parseInt(value))) {
      errors.push({
        message: this.i18n.gettext('Inserisci un numero intero'),
        code: null
      });
    } else {
      errors = this.validateMinMaxValue(value, props);
    }
    this.setState({ errors: errors });
  }

  async handleChange(event) {
    let val = event.target.value;
    if (this.shouldComponentShowConfirmModal()) {
      this.tempvalue = val;
      this.showConfirmModal();
    } else {
      await this.setState({ isChosen: true, value: val });
      this.doChange();
    }
  }

  doChange() {
    let val = this.state.value || 0;
    this.props.delegate.setConfigurationItem(this.name, val, null, true);
    if (!val) {
      this.collectErrors();
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    var nextSelectedValue = this.state.value;
    if (
      this.props.items.min !== nextProps.items.min ||
      this.props.items.max !== nextProps.items.max ||
      this.props.selected !== nextProps.selected
    ) {
      nextSelectedValue = nextProps.selected;
      // let stateval = nextSelectedValue !== 0 ? nextSelectedValue : null; //se è zero non lo mostro
      let stateval = nextSelectedValue || null; //se è zero non lo mostro
      this.setState({ value: stateval });
      this.collectErrors(nextSelectedValue, nextProps);
      this.propagateErrors();
    }
    let disabledWidgets = await this.props.delegate.getdisabledWidgets();
    if (disabledWidgets.indexOf(this.name) >= 0) {
      this.disable();
    } else {
      this.enable();
    }
  }

  shouldComponentShowConfirmModal() {
    /*
     * Override this method if there is a cover editor widget
     * */
    return false;
  }

  async onModalConfirmClick() {
    /*
     * Override this method for the correct callback
     * */
    return true;
  }

  renderContext() {
    let text = this.getHelperText();

    return (
      <div className="" id={this.name}>
        <div className="product__formats" data-product-formats="">
          <div className="row">
            <div className="col-xs-12 col-sm-1 col-md-2 no-padding-col">
              <input
                type="number"
                className="form-control number__sheets"
                value={this.state.value || ''}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-xs-12 col-sm-11 col-md-10 no-padding-col">
              <AeMarkupText text={text} />
            </div>
          </div>
        </div>
        {this.renderErrors()}
      </div>
    );
  }
}
