import React from 'react';
import { AeIframe } from '@photosi/albumepoca-ui';
import Modal from 'react-modal';

import BaseBootstrapWidget from '../../core/components/baseBootstrapWidget';

/*
 * Render button and open cover editor into an iframe.
 * */
export class BaseCoverEditorWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);
    this.state = {
      errors: []
    };
    this.handleClick = this.handleClick.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
  }

  get name() {
    return 'coverEditor';
  }

  get label() {
    return this.i18n.gettext('personalizza la copertina');
  }

  get buttonLabel() {
    return this.i18n.gettext("Apri l'editor della copertina");
  }

  get loadingClass() {
    return this.loadingClassActive && this.loading ? `${this._loadingClass}` : null;
  }

  async handleClick() {
    if (!this.loading) {
      this.props.delegate.showCoverEditorIframe();
    }
  }

  UNSAFE_componentWillReceiveProps() {
    let errors = [];
    if (!this.props.delegate.hasCoverEditorWidget()) {
      errors = [];
    } else if (
      !this.props.delegate.isCoverEditorProjectValid() &&
      this.props.delegate.isCoverEditorProjectInitialized()
    ) {
      errors.push({
        message: this.i18n.gettext('Per proseguire devi configurare la copertina'),
        code: this.name
      });
    } else if (this.props.delegate.coverEditorNotConfigured) {
      errors.push({
        message: this.i18n.gettext('Per proseguire devi configurare la copertina'),
        code: this.name
      });
    }
    this.setState({ errors: errors });
  }

  renderCoverEditorIframe() {
    let modalHeight = parseInt((window.innerHeight * 97) / 100);

    let iframe = this.props.coverEditorApplicationUrl ? (
      <AeIframe url={this.props.coverEditorApplicationUrl} height={modalHeight} show={true} />
    ) : null;
    return iframe;
  }

  /* Add class to body */
  afterOpenModal() {
    window.document.body.classList.add('ReactModal__Body--open');
  }

  renderItems() {
    let items = [];
    if (this.props.items && this.props.items.length > 0) {
      items = (
        <div className="col-xs-6 col-sm-6 col-md-6 no-padding-col">
          <div
            className="btn-ae text-center"
            onClick={this.handleClick}
            data-action="set_cover_editor_button">
            {this.buttonLabel}{' '}
          </div>
        </div>
      );
    }
    return items;
  }

  render() {
    return (
      <div
        className={`${this.visibleCLass()} ${this.loadingClass}`}
        ref={(div) => {
          this.widgetElement = div;
        }}>
        {this.renderLabel()}
        <div id={this.name}>
          <div className="product__formats" data-product-formats="">
            <div className="row">{this.renderItems()}</div>
            {this.renderErrors()}
          </div>
        </div>
        <Modal
          contentLabel=""
          isOpen={this.props.showCoverEditor}
          onAfterOpen={this.afterOpenModal}
          ariaHideApp={false}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.30)',
              zIndex: 14
            },
            content: {
              zIndex: 14,
              outline: 'none',
              width: '98%',
              margin: '1%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              border: 'none',
              background: 'none',
              borderRadius: 0,
              padding: '0px',
              overflow: 'hidden'
            }
          }}>
          <div className="row">
            <div className="col-xs-12">{this.renderCoverEditorIframe()}</div>
          </div>
        </Modal>
      </div>
    );
  }
}
