import React from 'react';

import { conf } from 'outlinejs';
import { views } from 'outlinejs';
import { components } from 'outlinejs';
import { routing } from 'outlinejs';

import { AeFooter, AeModal, AeHeader, AeAffix, AeNotify, AeLoader } from '@photosi/albumepoca-ui';
import { Price } from './components/price';
import { Promo } from './components/promo';
import { CtaButton } from './components/ctaButton';
import { CookieConsent } from './components/CookieConsent';

import { BreadcrumbComponent } from './components/breadcrumbComponent';
import Logger from './logger';
import { defaultCenter } from './notification-center';
import { GuiErrors, GuiHttpErrors, GuiNotifications } from './utils/index';
import {
  getAffixTopBreakPoint,
  getAffixValues,
  HEADER_AND_BREADCRUMB_HEIGHT,
  PRODUCT_PREVIEW_PADDING_RIGHT,
  PRODUCT_PREVIEW_PADDING_BOTTOM,
  PRODUCT_PREVIEW_MIN_WIDTH,
  FILTERS_VIEW_MIN_HEIGHT
} from './utils/domFunctions';
import { impersonationActive } from './utils';
import { AlertWithHideButton } from './components/AlertWithHideButton';

import './styles/main.scss';

export class LayoutView extends views.BaseLayoutView {
  constructor(props) {
    super(props);

    this.layoutViewElement = null;

    this.state = {
      showModal: false,
      textModal: '',
      cancelLabelModal: null,
      errorDetailModal: null,
      confirmLabelModal: null,
      titleModal: null,
      confirmCallbackModal: null,
      cancelCallbackModal: null,
      menu: [],
      showNotify: false,
      textNotify: '',
      closeCallback: null,
      dismissCallback: null,
      dismissRange: 2000,
      showAlertWithHideButton: false,
      showAlertWithHideButtonMessage: undefined,
      showAlertWithHideButtonTitle: undefined,
      showAlertWithHideButtonStorageKey: undefined
    };

    this.handleBrowserHistory = this.handleBrowserHistory.bind(this);
  }

  handleBrowserHistory(event) { //eslint-disable-line
    console.log('Back to previous history (' + window.location.href + ' )');
  }

  componentDidMount() {
    const errorEvent = GuiErrors.modalErrorEvent;
    const infoEvent = GuiErrors.modalInfoEvent;
    const confirmActionEvent = GuiNotifications.modalConfirmActionEvent;
    const notificationEvent = GuiNotifications.notificationEvent;
    const configuration404Event = GuiHttpErrors.configuration404Event;
    const modalWithHideButtonEvent = GuiNotifications.modalWithHideButtonEvent;

    defaultCenter.on(
      errorEvent,
      (errorText, errorDetailModal, cancelLabel, onConfirmCallback, onCancelCallback) => {
        // this.showModal(errorText, buttonlabel, errorDetailModal);
        let title = this.i18n.gettext('Attenzione');
        this.showModal(
          errorText,
          cancelLabel,
          errorDetailModal,
          null,
          title,
          onConfirmCallback,
          onCancelCallback
        );
      }
    );

    defaultCenter.on(
      infoEvent,
      (content, title, cancelLabel, onConfirmCallback, onCancelCallback) => {
        // this.showModal(infoText);
        title = title || this.i18n.gettext('Attenzione');
        this.showModal(
          content,
          cancelLabel,
          null,
          null,
          title,
          onConfirmCallback,
          onCancelCallback
        );
      }
    );

    defaultCenter.on(
      confirmActionEvent,
      (content, title, confirmLabel, cancelLabel, onConfirmCallback, onCancelCallback) => {
        title = title || this.i18n.gettext('Attenzione');
        this.showModal(
          content,
          cancelLabel,
          null,
          confirmLabel,
          title,
          onConfirmCallback,
          onCancelCallback
        );
      }
    );

    //Notification Event
    defaultCenter.on(notificationEvent, (notificationText, notificationRange) => {
      this.showNotification(
        notificationText,
        notificationRange,
        this.clearNotificationParams.bind(this),
        this.clearNotificationParams.bind(this)
      );
    });

    //Http errors
    defaultCenter.on(
      configuration404Event,
      (content, title, cancelLabel, onConfirmCallback, onCancelCallback) => {
        title = title || this.i18n.gettext('Attenzione');
        content =
          content ||
          this.i18n.gettext(
            'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
          );
        if (!onCancelCallback) {
          if (this.delegate && this.delegate.redirectToHomepage) {
            onCancelCallback = this.delegate.redirectToHomepage.bind(this.delegate);
          } else {
            console.error('redirectToHomepage function not defined');
          }
        }
        this.showModal(
          content,
          cancelLabel,
          null,
          null,
          title,
          onConfirmCallback,
          onCancelCallback
        );
      }
    );

    // modalWithHideButtonEvent
    defaultCenter.on(modalWithHideButtonEvent, (title, message, storageKey) => {
      this.showAlertWithHideButtonModal(title, message, storageKey);
    });

    // patch browser history navigation
    window.addEventListener('popstate', this.handleBrowserHistory);
  }

  componentWillUnmount() {
    // patch browser history navigation
    window.removeEventListener('popstate', this.handleBrowserHistory);
  }

  getElementsWithError() {
    return this.layoutViewElement.querySelectorAll('.alert-danger');
  }

  isCoverEditorWidgetBlocked() {
    let elements = this.getElementsWithError();
    let result = false;

    for (var elementIndex = 0; elementIndex < elements.length; elementIndex++) {
      if (elements[elementIndex].id !== 'coverEditor') {
        result = true;
        break;
      }
    }
    return result;
  }

  scrollToFirstWidgetErrorExceptCoverEditor() {
    let elements = this.getElementsWithError();
    let element;
    for (var elementIndex = 0; elementIndex < elements.length; elementIndex++) {
      if (elements[elementIndex].id !== 'coverEditor') {
        element = elements[elementIndex];
        break;
      }
    }
    if (element) {
      let div = element.closest('div.block__widget');
      if (div) {
        div.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }

  // probabilmente andrà spostato in una parte più specifica
  scrollToFirstWidgetError() {
    let element = this.getElementsWithError()[0];
    if (element) {
      let div = element.closest('div.block__widget');
      if (div) {
        this.props.delegate.fixScrollToNode(div);
      }
    }
  }

  async goToRegistering() {
    let registeringUrl;
    switch (this.request.language) {
      case 'it':
        registeringUrl = conf.settings.AE_REGISTRATION_IT_URL;
        break;
      case 'es':
        registeringUrl = conf.settings.AE_REGISTRATION_ES_URL;
        break;
      default:
        registeringUrl = conf.settings.AE_REGISTRATION_EN_URL;
    }

    this.response.navigate(registeringUrl);
  }

  async goToNoProfessional() {
    let noProfessionalUrl = `${conf.settings.HOMEPAGE_BASE_URL}${this.request.language}/${conf.settings.NO_PROFESSIONAL_PATH}`;
    this.response.navigate(noProfessionalUrl);
  }

  showModal(
    text,
    cancelLabelModal = null,
    errorDetailModal = null,
    confirmLabelModal = null,
    titleModal = null,
    onConfirmCallbackModal = null,
    onCancelCallbackModal = null
  ) {
    if (onConfirmCallbackModal == null && cancelLabelModal == null) {
      cancelLabelModal = this.i18n.gettext('chiudi');
    }

    async function cancelCallbackModal() {
      if (onCancelCallbackModal) {
        await onCancelCallbackModal();
      }
      this.clearModalParams();
    }

    async function confirmCallbackModal() {
      await onConfirmCallbackModal();
      this.clearModalParams();
    }

    this.setState({
      showModal: true,
      textModal: text,
      cancelLabelModal: cancelLabelModal,
      errorDetailModal: errorDetailModal,
      confirmLabelModal: confirmLabelModal,
      titleModal: titleModal,
      confirmCallbackModal: onConfirmCallbackModal ? confirmCallbackModal.bind(this) : null,
      cancelCallbackModal: cancelCallbackModal.bind(this)
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
      textModal: null,
      cancelLabelModal: null,
      errorDetailModal: null,
      confirmLabelModal: null,
      titleModal: null,
      confirmCallbackModal: null,
      cancelCalbackModal: null
    });
  }

  showNotification(notifyText, dismissRange, closeCallback, dismissCallback) {
    this.setState({
      showNotify: true,
      notifyText: notifyText,
      closeCallback: closeCallback,
      dismissCallback: dismissCallback,
      dismissRange: dismissRange
    });
  }

  closeNotification() {
    this.setState({
      showNotify: false,
      notifyText: null,
      closeCallback: null,
      dismissCallback: null,
      dismissRange: null
    });
  }

  showAlertWithHideButtonModal(title, message, storageKey) {
    this.setState({
      showAlertWithHideButton: true,
      showAlertWithHideButtonMessage: message,
      showAlertWithHideButtonTitle: title,
      showAlertWithHideButtonStorageKey: storageKey
    });
  }

  hideAlertWithHideButtonModal() {
    this.setState({
      title: undefined,
      message: undefined,
      storageKey: undefined,
      showAlertWithHideButton: false
    });
  }

  /**
   * clear all the modal param for avoiding junk variables
   * the modal actual closing is handled by the AeCustomModal react component itself
   */
  async clearModalParams() {
    await this.setState({
      showModal: false,
      textModal: null,
      cancelLabelModal: null,
      errorDetailModal: null,
      confirmLabelModal: null,
      titleModal: null,
      confirmCallbackModal: null,
      cancelCalbackModal: null
    });
  }

  async clearNotificationParams() {
    await this.setState({
      showNotify: false,
      notifyText: null,
      closeCallback: null,
      dismissCallback: null,
      dismissRange: null
    });
  }

  render() {
    const {
      showModal,
      textModal,
      cancelLabelModal,
      errorDetailModal,
      confirmLabelModal,
      titleModal,
      confirmCallbackModal,
      cancelCallbackModal,
      showNotify,
      notifyText,
      closeCallback,
      dismissCallback,
      dismissRange,
      showAlertWithHideButton,
      showAlertWithHideButtonMessage,
      showAlertWithHideButtonTitle,
      showAlertWithHideButtonStorageKey
    } = this.state;

    const nextUrlEncoded = encodeURIComponent(this.request.absoluteUrl);
    const logoutUrl = routing.Utils.reverse('logout');
    const logoutUrlWithNextUrl = `${logoutUrl}?next-url=${nextUrlEncoded}`;
    const loginUrl = routing.Utils.reverse('login');
    const loginUrlWithNextUrl = `${loginUrl}?next-url=${nextUrlEncoded}`;
    const affixTopBreakPoint = getAffixTopBreakPoint();
    const homepageUrl = `${conf.settings.HOMEPAGE_BASE_URL}${this.request.language}/`;

    const { totalCartProjectsQuantity, totalBucketProjectsQuantity, breadcrumbBarHidden } =
      this.props.contentProps;
    const user = this.props.contentProps.user || this.request.user;
    const viewHasFooter =
      this.props.contentProps.viewHasFooter !== undefined
        ? this.props.contentProps.viewHasFooter
        : true;

    const impersonationIsActive = impersonationActive();
    return (
      <div
        ref={(div) => {
          this.layoutViewElement = div;
        }}>
        <CookieConsent />
        <div className="blockContainerHeader">
          <AeHeader
            lang={this.request.language}
            user={user}
            logoutUrl={logoutUrlWithNextUrl}
            loginUrl={loginUrlWithNextUrl}
            goToRegistering={this.goToRegistering.bind(this)}
            goToNoProfessional={this.goToNoProfessional.bind(this)}
            cartUrl={`${conf.settings.CART_BASE_URL}`}
            cartItemsTotal={totalCartProjectsQuantity}
            bucketUrl={`${conf.settings.CART_BASE_URL}#bucket`}
            bucketItemsTotal={totalBucketProjectsQuantity}
            homepageUrl={homepageUrl}
            adminAreaUrl={`${conf.settings.MYALBUMEPOCA_ORDERS_URL}`}
            impersonationActive={impersonationIsActive}
          />
          <div className="hidden-xs">
            <AeAffix offsetTop={affixTopBreakPoint}>
              <BreadcrumbComponent delegate={this.delegate} isDisabled={breadcrumbBarHidden} />
            </AeAffix>
          </div>
        </div>
        <div className="blockContainerBody">
          <div className="mainContent">{this.renderContent()}</div>
          {viewHasFooter && <AeFooter lang={this.request.language} />}
          <AeModal
            isOpen={showModal}
            text={textModal}
            title={titleModal}
            moreDetail={errorDetailModal}
            moreDetailLabel={this.i18n.gettext('dettagli')}
            confirmLabel={confirmLabelModal}
            dismissLabel={cancelLabelModal}
            onConfirm={confirmCallbackModal}
            onDismiss={cancelCallbackModal}
          />
          <AeNotify
            isActive={showNotify}
            message={notifyText}
            onClick={closeCallback}
            onDismiss={dismissCallback}
            action={'X'}
            dismissAfter={dismissRange}
          />
          <AlertWithHideButton
            isOpen={showAlertWithHideButton}
            message={showAlertWithHideButtonMessage}
            title={showAlertWithHideButtonTitle}
            storageKey={showAlertWithHideButtonStorageKey}
            onClose={this.hideAlertWithHideButtonModal.bind(this)}
          />
        </div>
      </div>
    );
  }
}

/*
 * Provide a base configurator area
 * with product and filters areas
 */
export class BaseContentView extends views.BaseView {
  render(ProductView, FiltersView) {
    let content = (
      <div>
        <div className="col-md-7">
          <ProductView
            delegate={this.delegate}
            loadingCollection={this.props.loadingCollection}
            product={this.props.product}
            productConfigurationIsLoading={this.props.productConfigurationIsLoading}
            priceValidity={this.props.priceValidity}
            filtersViewValidity={this.props.filtersViewValidity}
            loadingPreview={this.props.loadingPreview}
            updatePrice={this.props.updatePrice}
          />
        </div>
        <div className="col-md-5">
          <FiltersView
            delegate={this.delegate}
            filters={this.props.filters}
            configuration={this.props.configuration}
            packagingConfiguration={this.props.packagingConfiguration}
            standConfiguration={this.props.standConfiguration}
            loadingCollection={this.props.loadingCollection}
            showCoverEditor={this.props.showCoverEditor}
            coverEditorUrl={this.props.coverEditorUrl}
            coverEditorProjectIsValid={this.props.coverEditorProjectIsValid}
            filtersValidationErrors={this.props.filtersValidationErrors}
            filtersViewValidity={this.props.filtersViewValidity}
          />
        </div>
      </div>
    );

    let contentBlockClass =
      this.props.loadingPage || this.props.initViewRendering ? 'content-block' : null;

    return (
      <div className="container">
        <div className="row">
          <AeLoader active={this.props.loadingPage || this.props.initViewRendering} />
          <div className={contentBlockClass}>{this.props.initViewRendering ? null : content}</div>
        </div>
      </div>
    );
  }
}

/*
 * Provide a base product area
 * with methods and components used by specific product view
 * that extends this component
 */
export class BaseProductView extends components.BaseComponent {
  constructor(...args) {
    super(...args);
    this.productPreviewElement = null;
    this.productPreviewTitleElement = null;

    this.state = {
      affixElementWidth: null,
      affixTopValue: null,
      affixTopBreakpoint: null,
      affixBottomBreakpoint: null,
      productPreviewWidth: null,
      productPreviewHeight: null
    };

    this.handleResize = this.handleResize.bind(this);
  }

  handleResize() {
    let affixElementWidth = this.productPreviewElement.clientWidth;

    let productPreviewTitleHeight = this.productPreviewTitleElement.clientHeight;

    let windowHeight =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    let productPreviewMaxHeight = affixElementWidth;
    let productPreviewWidth = affixElementWidth - PRODUCT_PREVIEW_PADDING_RIGHT;
    let productPreviewHeight = productPreviewWidth;

    // calculate best width and height to fit product preview
    let productPreviewElementMaxHeight =
      HEADER_AND_BREADCRUMB_HEIGHT + productPreviewMaxHeight + productPreviewTitleHeight;
    if (productPreviewElementMaxHeight > windowHeight) {
      productPreviewHeight =
        windowHeight -
        HEADER_AND_BREADCRUMB_HEIGHT -
        productPreviewTitleHeight -
        PRODUCT_PREVIEW_PADDING_BOTTOM;
      productPreviewWidth = productPreviewHeight;
    }

    // check minimum value to ensure visualization on mobile
    if (productPreviewWidth < PRODUCT_PREVIEW_MIN_WIDTH) {
      productPreviewHeight = PRODUCT_PREVIEW_MIN_WIDTH;
      productPreviewWidth = PRODUCT_PREVIEW_MIN_WIDTH;
    }

    let affixValues = getAffixValues();

    let state = Object.assign(
      { affixElementWidth, productPreviewWidth, productPreviewHeight },
      affixValues
    );

    this.setState(state);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const isUserLoggedIn = this.props.delegate.isUserLoggedIn();
    const isOutOfStock = this.props.product.productItem.isOutOfStock;
    return (
      <div className="row productView">
        <div className="col-xs-12">
          <div
            ref={(affix) => {
              this.productPreviewElement = affix;
            }}>
            <AeAffix
              offsetTop={this.state.affixTopBreakpoint}
              offsetBottom={this.state.affixBottomBreakpoint}
              topValue={this.state.affixTopValue}>
              <div className="product__preview" style={{ width: this.state.affixElementWidth }}>
                {this.renderPreview()}
                <div
                  className="row product-preview-title"
                  ref={(div) => {
                    this.productPreviewTitleElement = div;
                  }}>
                  <Price
                    isLoading={this.priceIsLoading()}
                    priceValidity={this.props.priceValidity}
                    showWidgetPrice={this.props.product.showWidgetPrice}
                    price={this.props.product.price}
                    productType={this.props.product.productConfiguration.productType}
                    title={this.props.delegate.getTitle()}
                    productTitle={this.props.product.productName}
                    productPrice={this.props.product.productPrice}
                    productIsOutOfStock={isOutOfStock}
                  />
                  <div className="col-xs-6 col-sm-5 col-md-5 hidden-sm hidden-xs">
                    <CtaButton
                      productConfigurationIsLoading={this.props.productConfigurationIsLoading}
                      onSubmit={this.delegate.goToNextStep.bind(this.delegate)}
                      calltoaction={this.delegate.getCallToAction()}
                    />
                  </div>
                </div>
                <Promo
                  delegate={this.props.delegate}
                  isUserLoggedIn={isUserLoggedIn}
                  productType={this.props.product.productConfiguration.productType}
                />
              </div>
            </AeAffix>
          </div>
        </div>
      </div>
    );
  }
}

/*
 * Provide a base product area
 * with methods and components used by specific product view
 * that extends this component
 */
export class BaseFiltersView extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      showErrors: false,
      errors: {}
    };
    this.pushWidgetError = this.pushWidgetError.bind(this);
    this.popWidgetError = this.popWidgetError.bind(this);
  }

  componentDidMount() {
    //HACK: fix scrolling affix between pages
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) { //eslint-disable-line
    this.propagateErrors();
  }

  isValid() {
    let errors = this.state.errors;
    if (errors) {
      if (Object.keys(errors).length === 0) {
        return true;
      }
    }
    return false;
  }

  priceIsValid() {
    let priceValidiyWidgetList = conf.settings.EVENT_BOOK_PRICE_VALIDITY_WIDGET_LIST;
    for (let validityWidget of priceValidiyWidgetList) {
      if (this.state.errors[validityWidget]) {
        return false;
      }
    }
    return true;
  }

  propagateErrors() {
    let viewValidity = this.isValid();
    let priceValidity = this.priceIsValid();
    this.props.delegate.setFilterViewValidity(viewValidity);
    this.props.delegate.setPriceValidity(priceValidity);
  }

  /**   validation errors section  **/
  pushWidgetError(widgetName) {
    let errors = this.state.errors;
    errors[widgetName] = true;
    this.setState({ errors: errors });
    Logger.info('Error on widget:', widgetName);
  }

  popWidgetError(widgetName) {
    let errors = this.state.errors;
    if (errors[widgetName]) {
      delete errors[widgetName];
    }
    this.setState({ errors: errors });
    // Logger.info('- tolto errore', widgetName);
  }

  /** end validation errors section **/

  render() {
    // this value must be calculated from productView element
    const minHeight = `${FILTERS_VIEW_MIN_HEIGHT}px`;

    return (
      <div className="row product__settings--cover" style={{ minHeight }}>
        <div className="col-md-12">{this.getFilters()}</div>
        <div className="col-md-12  hidden-md hidden-lg">
          <CtaButton
            productConfigurationIsLoading={this.delegate.productConfigurationIsLoading}
            onSubmit={this.delegate.goToNextStep.bind(this.delegate)}
            calltoaction={this.delegate.getCallToAction()}
          />
        </div>
      </div>
    );
  }
}

/*
 * Provide a base view with only a loader
 */
export class BaseLoadingView extends views.BaseView {
  render() {
    return <AeLoader active={true} />;
  }
}
