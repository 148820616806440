import { runtime } from '@outlinejs/contexts';
import Logger from '../logger';

// header element
const HEADER_HEIGHT_PX = 100;
const HEADER_HEIGHT_COLLAPSED_PX = 0;
// nav element
const NAVBAR_HEIGHT_PX = 0;
const NAVBAR_HEIGHT_COLLAPSED_PX = 0;
// .cartnav element (breadcrumb)
const BREADCRUMB_HEIGHT_PX = 50;
// product preview container
export const PRODUCT_PREVIEW_PADDING_RIGHT = 70;
export const PRODUCT_PREVIEW_PADDING_BOTTOM = 16;
export const PRODUCT_PREVIEW_MIN_WIDTH = 300;

export const HEADER_AND_BREADCRUMB_HEIGHT =
  HEADER_HEIGHT_PX + NAVBAR_HEIGHT_PX + BREADCRUMB_HEIGHT_PX;
export const HEADER_AND_BREADCRUMB_COLLAPSED_HEIGHT =
  HEADER_HEIGHT_COLLAPSED_PX + NAVBAR_HEIGHT_COLLAPSED_PX + BREADCRUMB_HEIGHT_PX;

// product filters view
export const FILTERS_VIEW_MIN_HEIGHT = 710;

export function getFooterHeight() {
  let footer = document.querySelector('.footer');
  return calculateElementHeight(footer);
}

export function calculateElementHeight(element) {
  let elementStyle = element.currentStyle || window.getComputedStyle(element);
  let elementMarginTop = 0;
  let elementMarginBottom = 0;
  try {
    elementMarginTop = parseInt(elementStyle.marginTop.replace('px', ''));
    elementMarginBottom = parseInt(elementStyle.marginBottom.replace('px', ''));
  } catch (err) {
    console.error(element, err);
  }
  return element.clientHeight + elementMarginTop + elementMarginBottom;
}

export function getAffixTopBreakPoint() {
  return HEADER_HEIGHT_PX;
}

export function getAffixValues() {
  let affixValues = {
    affixTopValue: 0,
    affixBottomBreakpoint: 0
  };

  if (runtime.isClient) {
    let footerHeight = getFooterHeight() + 150;

    affixValues = {
      affixTopValue: NAVBAR_HEIGHT_PX + BREADCRUMB_HEIGHT_PX,
      affixTopBreakpoint: getAffixTopBreakPoint(),
      affixBottomBreakpoint: footerHeight
    };
  } else {
    Logger.error('getAffixValues - This function must be called when window object is available');
  }

  return affixValues;
}
