import React from 'react';
import classNames from 'classnames';

import { AeImage } from './AeImage';
//import {AeMarkupText} from '@photosi/albumepoca-ui';
import BaseBootstrapWidget from './baseBootstrapWidget';

export class GraphicCollapsableWithDescriptionWidget extends BaseBootstrapWidget {
  get name() {
    throw new Error('name must be defined');
  }

  get label() {
    throw new Error('label must be defined');
  }

  get calculatePrice() {
    return false;
  }

  get descriptionSubTitle() {
    throw new Error('descriptionSubTitle must be defined');
  }

  get deprecatedDescription() {
    throw new Error('deprecatedDescription must be defined');
  }

  get showDescription() {
    return true;
  }

  textDescription() {
    return (
      <p>
        {this.descriptionSubTitle}
        <br />
        <strong>Giotto</strong>, {this.i18n.gettext('Giotto material description')}
        <br />
        <strong>Michelangelo</strong>, {this.i18n.gettext('Michelangelo material description')}
        <br />
        <strong>Modigliani</strong>, {this.i18n.gettext('Modigliani material description')}
        <br />
        <strong>Tiziano</strong>, {this.i18n.gettext('Tiziano material description')}
        <br />
        <strong>Caravaggio</strong>, {this.i18n.gettext('Caravaggio material description')}
        <br />
        <strong>Leonardo</strong>, {this.i18n.gettext('Leonardo material description')}
        <br />
        <strong>Masaccio</strong>, {this.i18n.gettext('Masaccio material description')}
        <br />
        <strong>Raffaello</strong>, {this.i18n.gettext('Raffaello material description')}
        <br />
        <strong>Tintoretto</strong>, {this.i18n.gettext('Tintoretto material description')}
        <br />
      </p>
    );
  }

  itemClass(item) {
    return classNames('colors__item', { active: item.id === this.props.selected });
  }

  handleOpen() {
    this.setState({ isChosen: false });
  }

  handleMouseOver(item) {//eslint-disable-line
    return null;
  }

  handleMouseOut() {
    return null;
  }

  handleClick(item) {
    super.handleClick(item);
    if (this.scrollToWidgetIsActive) {
      this.props.delegate.scrollToWidget(this.widgetElement);
    }
  }

  /*render all the avalilable items*/
  renderItems() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        return (
          <div
            key={item.id}
            className="col-xs-6 col-sm-4 col-md-6 padding-color"
            onMouseOver={this.handleMouseOver.bind(this, item)}
            onMouseOut={this.handleMouseOut.bind(this)}>
            <a
              onClick={this.handleClick.bind(this, item)}
              className={this.itemClass(item)}
              data-action={`set_${this.props.items.name}_${item.id}`}>
              <span className="colors__item__image" style={{ backgroundColor: item.colorCode }}>
                <AeImage src={item.fileUrl} />
              </span>
              <span className="colors__item__text">
                {item.name}
                <br />
                {item.description}
              </span>
            </a>
          </div>
        );
      });
    }
    return renderedItems;
  }

  /*render only the selected item if available*/
  renderSelectedItem() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        if (item.id === this.props.selected) {
          return (
            <div key={item.id} className="col-xs-6 col-sm-10 col-md-10 padding-color vcenter">
              <a className={this.itemClass(item)}>
                <span className="colors__item__image" style={{ backgroundColor: item.colorCode }}>
                  <AeImage src={item.fileUrl} />
                </span>
                <span className="colors__item__text">
                  {item.name}
                  <br />
                  {item.description}
                </span>
              </a>
            </div>
          );
        }
      });
    }
    return renderedItems;
  }

  renderArrow() {
    return (
      <div className="col-xs-6 col-sm-2 col-md-2 text-right no-padding-col vcenter">
        <div className="arrowdown" />
      </div>
    );
  }

  /*render the collapsed version of the widget*/
  renderSummary() {
    return (
      <div className="product__formats">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 no-padding-col">
            <div
              className="btn-ae"
              onClick={this.handleOpen.bind(this)}
              data-action={`set_${this.props.items.name}_selected_${this.props.selected}`}>
              {this.renderSelectedItem()}
              {this.renderArrow()}
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  /*render the full length version of the widget*/
  renderFull() {
    return (
      <div className="product__formats">
        <div className="row">
          <div className="padding-color">
            {this.showDescription ? this.textDescription() : null}
          </div>
        </div>
        <div className="row" onMouseLeave={this.handleMouseOut.bind(this)}>
          {this.renderItems()}
        </div>
        {this.renderErrors()}
      </div>
    );
  }

  renderContext() {
    return (
      <div>
        {this.state.isChosen && this.props.items !== undefined && this.props.items.length !== 1
          ? this.renderSummary()
          : this.renderFull()}
      </div>
    );
  }
}
