export function albumFunnelSteps(req) {
  let steps = [
    {
      step: 1,
      route: 'album:main',
      text: req.i18n.gettext('personalizza')
    },
    {
      step: 2,
      route: 'album-packaging:main',
      text: req.i18n.gettext('personalizza la scatola')
    },
    {
      step: 3,
      route: null,
      text: req.i18n.gettext('scegli come impaginare')
    },
    {
      step: 4,
      route: null,
      text: req.i18n.gettext('carica le tue foto')
    },
    {
      step: 5,
      route: null,
      text: req.i18n.gettext('ordina')
    },
    {
      step: 6,
      route: null,
      text: req.i18n.gettext('conferma ordine')
    }
  ];
  return steps;
}

export function eventBookFunnelSteps(req) {
  let steps = [
    {
      step: 1,
      route: 'eventbook:main',
      text: req.i18n.gettext('personalizza')
    },
    {
      step: 2,
      route: 'eventbook-packaging:main',
      text: req.i18n.gettext('personalizza la scatola')
    },
    {
      step: 3,
      route: null,
      text: req.i18n.gettext('scegli come impaginare')
    },
    {
      step: 4,
      route: null,
      text: req.i18n.gettext('carica le tue foto')
    },
    {
      step: 5,
      route: null,
      text: req.i18n.gettext('ordina')
    },
    {
      step: 6,
      route: null,
      text: req.i18n.gettext('conferma ordine')
    }
  ];
  return steps;
}

export function mattedPrintsFunnelSteps(req) {
  let steps = [
    {
      step: 1,
      route: 'matted-prints:main',
      text: req.i18n.gettext('personalizza')
    },
    {
      step: 2,
      route: 'matted-prints-packaging:main',
      text: req.i18n.gettext('personalizza la scatola')
    },
    {
      step: 3,
      route: null,
      text: req.i18n.gettext('scegli come impaginare')
    },
    {
      step: 4,
      route: null,
      text: req.i18n.gettext('carica le tue foto')
    },
    {
      step: 5,
      route: null,
      text: req.i18n.gettext('ordina')
    },
    {
      step: 6,
      route: null,
      text: req.i18n.gettext('conferma ordine')
    }
  ];
  return steps;
}

export function usbDriveFunnelSteps(req) {
  let steps = [
    {
      step: 1,
      route: 'usb-box:main',
      text: req.i18n.gettext('personalizza')
    },
    {
      step: 2,
      route: null,
      text: req.i18n.gettext('ordina')
    },
    {
      step: 3,
      route: null,
      text: req.i18n.gettext('conferma ordine')
    }
  ];
  return steps;
}

export function frameFunnelSteps(req) {
  let steps = [
    {
      step: 1,
      route: 'frame:main',
      text: req.i18n.gettext('personalizza')
    },
    {
      step: 2,
      route: null,
      text: req.i18n.gettext('carica le tue foto')
    },
    {
      step: 3,
      route: null,
      text: req.i18n.gettext('ordina')
    },
    {
      step: 4,
      route: null,
      text: req.i18n.gettext('conferma ordine')
    }
  ];
  return steps;
}
