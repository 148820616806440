import React from 'react';

import BaseBootstrapWidget from './baseBootstrapWidget';
import { getProjectName, setProjectName } from '../../projects/utils';

export class ProjectNameWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);
    this.state = {
      isChosen: true,
      value: null,
      errors: [],
      showErrors: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  get name() {
    return 'projectName';
  }

  get label() {
    return this.i18n.gettext('Nome del progetto');
  }

  async componentDidMount() {
    let val = await getProjectName(this.props.delegate.productType);
    if (val) {
      this.setState({ value: val });
    }
  }

  async handleChange(event) {
    let val = event.target.value;
    this.setState({ value: val, showErrors: true });
    if (this.state.errors.length !== 0) {
      this.propagateErrors();
    }
  }

  async handleBlur() {
    await setProjectName(this.state.value, this.props.delegate.productType);
  }

  async collectErrors(value) {
    let errors = [];
    if (value && value.length > 99) {
      errors.push({
        message: this.i18n.gettext('Il testo non può superare i 100 caratteri'),
        code: null
      });
    }
    this.setState({ errors: errors });
  }

  render() {
    return (
      <div className="block__widget">
        {this.renderLabel()}
        <div className="" id="">
          <div className="product__formats" data-product-formats="">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 padding-col-dx">
                <input
                  type="text"
                  className="form-control text__targa"
                  placeholder={this.i18n.gettext('Dai un nome al tuo progetto')}
                  value={this.state.value || ''}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                />
              </div>
            </div>
            {this.renderErrors()}
          </div>
        </div>
      </div>
    );
  }
}
