import React from 'react';
import { AeImage } from './AeImage';
import { GraphicCollapsableWidgetWithText } from './graphicCollapsableWidgetWithText';

export class BaseElementColorsWidget extends GraphicCollapsableWidgetWithText {
  get name() {
    return 'packagingUvGraphicElementColorType';
  }

  get label() {
    return this.i18n.gettext('colore della grafica');
  }

  get calculatePrice() {
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items && this.props.selected !== nextProps.selected) {
      this.setState({ isChosen: false }); //need to reopen the item selector
    }

    //ned to set the new svg color
    if (nextProps.items && this.props.items !== nextProps.items) {
      if (nextProps.items.length === 0) {
        this.props.delegate.resetPreviewSvgColor();
      }

      let setColor;
      nextProps.items.each(
        function (item) {
          if (this.props.delegate.packagingConfiguration.uvGraphicColorSelected) {
            setColor = nextProps.selected && item.id === nextProps.selected;
          } else {
            setColor = item.isDefault;
          }
          if (setColor) {
            this.props.delegate.chosePreviewSvgColor(item.colorCode);
            this.props.delegate.setPreviewSvgColor(item.colorCode);
            this.props.delegate.setPreviewSvg();
          }
        }.bind(this)
      );
    }
  }

  handleMouseOver(item) {
    this.props.delegate.setPreviewSvgColor(item.colorCode);
    this.props.delegate.setPreviewSvg();
  }

  handleMouseOut() {
    this.props.delegate.setPreviewSvgColor();
    this.props.delegate.setPreviewSvg();
  }

  handleClick(item) {
    super.handleClick(item);
    this.props.delegate.packagingConfiguration.uvGraphicColorSelected = true;
    this.props.delegate.chosePreviewSvgColor(item.colorCode);
    this.props.delegate.setPreviewSvgColor(item.colorCode);
  }

  /*render all items*/
  renderItems() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        return (
          <div
            key={item.id}
            className="col-xs-6 col-sm-4 col-md-4 padding-color"
            onMouseOver={this.handleMouseOver.bind(this, item)}
            onMouseOut={this.handleMouseOut.bind(this)}>
            <a
              onClick={this.handleClick.bind(this, item)}
              className={this.itemClass(item)}
              data-action={`set_${this.props.items.name}_${item.id}`}>
              <span className="colors__item__image" style={{ backgroundColor: item.colorCode }}>
                <AeImage src={item.fileUrl} />
              </span>
              <span className="colors__item__text">
                {item.name}
                <br />
                {item.description}
              </span>
            </a>
          </div>
        );
      });
    }
    return renderedItems;
  }

  renderContext() {
    return <div>{this.renderFull()}</div>;
  }
}
