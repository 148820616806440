import React from 'react';

import { BaseTextWidget } from './baseTextWidget';

import { AeMarkupText } from '@photosi/albumepoca-ui';

export class BaseUvGraphicTextWidget extends BaseTextWidget {
  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    let row1 = 0;
    let row2 = 0;

    if (nextProps.items && nextProps.items.map) {
      nextProps.items.map((item) => {
        row1 = item.charLimitRow1 || 0;
        row2 = item.charLimitRow2 || 0;
      });
    }

    this.props.delegate.chosePreviewTexLimit(row1, row2);
  }

  async handleFocus(item) {
    //se il testo non è stato digitato dall'utente E non proviene da una configurazione salvata o ereditata lo resetto
    if (!this.props.delegate.isSvgTextTypedByUser() && !this.props.value[1]) {
      this.props.delegate.chosePreviewSvgText();
      if (item.codeRow1) {
        this.props.delegate.setConfigurationTexts(this.name, item.codeRow1, '');
      }
      if (item.codeRow2) {
        this.props.delegate.setConfigurationTexts(this.name, item.codeRow2, '');
      }
      await Promise.all([this.collectErrors(item, '', 1), this.collectErrors(item, '', 2)]);
      this.propagateErrors();
    }
    this.props.delegate.setPreviewSvg();
  }

  getItems() {
    let items = [];
    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        let captionrow = <div className="row"></div>;
        if (!item.nameRow2) {
          captionrow = (
            <div className="col-xs-12 col-sm-12 col-md-12 padding-col-dx">
              <AeMarkupText
                text={this.i18n.gettext(
                  "Il formato selezionato consente l'inserimento di una sola riga di testo."
                )}
              />
            </div>
          );
        }

        return (
          <div key={item.id} className="row">
            {this.renderRow1(item)}
            {this.renderRow2(item)}
            {captionrow}
          </div>
        );
      });
    }
    return items;
  }

  async handleChangeText(item, index, event) {
    this.setState({
      showErrors: true
    });
    let string = event.target.value;
    this.props.delegate.chosePreviewSvgText();
    if (!/[^\u0000-\u00ff]/g.test(string)) { //eslint-disable-line
      this.props.delegate.setConfigurationTexts(this.name, item['codeRow' + index], string);
      await this.collectErrors(item, string, index);
    }
    this.propagateErrors();
    this.props.delegate.setPreviewSvg();
  }
}
