import { conf } from 'outlinejs';
import amplitude from 'amplitude-js';
import { impersonationActive } from './utils';

export function productCategoryInterface(productType) {
  let productCategory;

  switch (productType) {
    case 'Album':
    case 'AlbumPackaging':
      productCategory = 'Album';
      break;
    case 'EventBook':
    case 'EventBookPackaging':
      productCategory = 'EventBook';
      break;
    case 'MattedPrints':
    case 'MattedPrintsPackaging':
      productCategory = 'MattedPrints';
      break;
    case 'UsbDrivePackaging':
      productCategory = 'Usb&Box';
      break;
    case 'HomeDecor':
      productCategory = 'Wall&TableDecor';
      break;
    default:
      throw new Error(`${productType} productCategoryInterface not implemented`);
  }

  return productCategory;
}

export function productInterface(userConfiguration) {
  let product;
  switch (userConfiguration.productType) {
    case 'Album':
      product = {
        product_category: productCategoryInterface(userConfiguration.productType),
        product_type: userConfiguration.productType,
        product_code: userConfiguration.configurationFamily,
        product_format: userConfiguration.formatType,
        product_pages_number: userConfiguration.pagesNumber
      };
      break;
    case 'AlbumPackaging':
      product = {
        product_category: productCategoryInterface(userConfiguration.productType),
        product_type: userConfiguration.productType,
        product_code: userConfiguration.packagingType,
        product_format: userConfiguration.formatType
      };
      break;
    case 'EventBook':
      product = {
        product_category: productCategoryInterface(userConfiguration.productType),
        product_type: userConfiguration.productType,
        product_code: userConfiguration.coverMaterialType || userConfiguration.coverType,
        product_format: userConfiguration.formatType,
        product_pages_number: userConfiguration.pagesNumber
      };
      break;
    case 'EventBookPackaging':
      product = {
        product_category: productCategoryInterface(userConfiguration.productType),
        product_type: userConfiguration.productType,
        product_code: userConfiguration.packagingType,
        product_format: userConfiguration.formatType
      };
      break;
    case 'MattedPrints':
      product = {
        product_category: productCategoryInterface(userConfiguration.productType),
        product_type: userConfiguration.productType,
        product_code: userConfiguration.frameColorMaterialType,
        product_format: userConfiguration.formatType,
        product_quantity: userConfiguration.quantity
      };
      break;
    case 'MattedPrintsPackaging':
      product = {
        product_category: productCategoryInterface(userConfiguration.productType),
        product_type: userConfiguration.productType,
        product_code: userConfiguration.packagingType,
        product_format: userConfiguration.formatType
      };
      break;
    case 'UsbDrivePackaging':
      product = {
        product_category: productCategoryInterface(userConfiguration.productType),
        product_type: userConfiguration.productType,
        product_code: userConfiguration.packagingType,
        product_format: userConfiguration.formatType
      };
      break;
    case 'HomeDecor':
      product = {
        product_category: productCategoryInterface(userConfiguration.productType),
        product_type: userConfiguration.productType,
        product_code: userConfiguration.commercialProduct,
        product_format: userConfiguration.format,
        product_quantity: userConfiguration.quantity
      };
      break;
    default:
      throw new Error(`${userConfiguration.productType} productInterface not implemented`);
  }

  return product;
}

const EVENTS_QUEUE = [];

export default class EventTracker {
  static get active() {
    // Don't initialize class for Datadog automatic tests and for staff users
    return window._DATADOG_SYNTHETICS_BROWSER === undefined && !impersonationActive();
  }

  static initialize() {
    amplitude.getInstance().init(conf.settings.AMPLITUDE_API_KEY, null, {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true
    });
    if (EVENTS_QUEUE.length !== 0) {
      EVENTS_QUEUE.forEach((eventCallback) => {
        eventCallback();
      });
      EVENTS_QUEUE.length = 0;
    }
  }

  static removeTrackingForUser() {
    if (EventTracker.active) {
      amplitude.getInstance().setUserId(null);
      amplitude.getInstance().clearUserProperties();
      amplitude.getInstance().regenerateDeviceId();
    }
  }

  static addUserInfo(user) {
    if (user) {
      const userProps = {
        payment_type: user.paymentType,
        membership_type:
          user.membershipInfo &&
          user.membershipInfo['isActive'] &&
          user.membershipInfo['membershipCode']
            ? user.membershipInfo['membershipCode']
            : null
      };

      amplitude.getInstance().setUserId(user.shopCode);
      amplitude.getInstance().setUserProperties(userProps);
    }
  }

  static log(user, eventName, eventProperties) {
    const eventCallback = () => {
      if (EventTracker.active) {
        EventTracker.addUserInfo(user);
        amplitude.getInstance().logEvent(eventName, eventProperties);
      }
    };

    if (window.AMPLITUDE_CONSENT_ENABLED === undefined) {
      EVENTS_QUEUE.push(eventCallback);
      return;
    }

    if (window.AMPLITUDE_CONSENT_ENABLED === false) {
      return;
    }

    eventCallback();
  }
}
