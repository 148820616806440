import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';

import { LanguageStorage } from './storage/index';

export default class {
  async processRequest(request) {
    if (runtime.isClient) {
      // set language
      let currentLanguage;
      let querystringLanguage = request.query.language;
      currentLanguage = await LanguageStorage.getLanguage();

      if (querystringLanguage) {
        if (conf.settings.LANGUAGES.indexOf(querystringLanguage) > -1) {
          currentLanguage = querystringLanguage;
          await LanguageStorage.setLanguage(currentLanguage);
        }
      }

      request.language = currentLanguage;
    } else {
      request.language = conf.settings.DEFAULT_LANGUAGE;
    }
  }
}
