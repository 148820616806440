import React from 'react';
import classNames from 'classnames';
import { AeImage } from '../../core/components/AeImage';

import BaseBootstrapWidget from './baseBootstrapWidget';

export class BaseColorThreeColumnsWidget extends BaseBootstrapWidget {
  get name() {
    throw new Error('name must be defined');
  }

  get label() {
    throw new Error('label must be defined');
  }

  get calculatePrice() {
    return false;
  }

  handleMouseOver(item) { //eslint-disable-line
    throw new Error('handleMouseOver must be defined');
  }

  handleMouseOut() {
    throw new Error('handleMouseOut must be defined');
  }

  // handleClick(item) { //eslint-disable-line
  //   super.handleClick(item);
  // }

  itemClass(item) {
    return classNames('colors__item', { active: item.id === this.props.selected });
  }

  /*render all items*/
  renderItems() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        return (
          <div
            key={item.id}
            className="col-xs-6 col-sm-4 col-md-4 padding-color"
            onMouseEnter={this.handleMouseOver.bind(this, item)}
            onMouseLeave={this.handleMouseOut.bind(this)}>
            <a
              onClick={this.handleClick.bind(this, item)}
              className={this.itemClass(item)}
              data-action={`set_${this.props.items.name}_${item.id}`}>
              <span className="colors__item__image" style={{ backgroundColor: item.colorCode }}>
                {item.fileUrl ? <AeImage src={item.fileUrl} /> : null}
              </span>
              <span className="colors__item__text">
                {item.name}
                <br />
                {item.description}
              </span>
            </a>
          </div>
        );
      });
    }
    return renderedItems;
  }

  renderContext() {
    return (
      <div
        className="product__formats"
        data-product-formats=""
        onMouseLeave={this.handleMouseOut.bind(this)}>
        <div className="row">{this.renderItems()}</div>
        {this.renderErrors()}
      </div>
    );
  }
}
