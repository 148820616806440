import React from 'react';

import { components } from 'outlinejs';

import classNames from 'classnames';

export class CtaButton extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.submitCount = 0;
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit() {
    if (this.submitCount === 0) {
      this.submitCount += 1;
      await this.props.onSubmit();
      this.submitCount = 0;
    }
  }

  render() {
    let loadingConfirmButtonClass = classNames('text-center', 'product__cta__btns', {
      'product__cta__btns loading-style': this.props.productConfigurationIsLoading
    });

    return (
      <div>
        <a
          className={loadingConfirmButtonClass}
          onClick={this.onSubmit}
          data-action="set_handleSubmit_packaging">
          {this.props.calltoaction}
        </a>
      </div>
    );
  }
}
