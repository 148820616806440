import { BaseCollection as BaseBackboneCollection } from 'outlinejs-backbone-model/lib/managers';
import { oauth2Sync } from './models';
import { conf } from 'outlinejs';

import { ProfessionalPreOrder } from './models';

export class BaseCollection extends BaseBackboneCollection {
  sync(method, model, options = {}) {
    return oauth2Sync(super.sync, method, model, options);
  }
}
export class LoadableCollection extends BaseCollection {
  loadByParams(params) {
    return this.fetch({ data: params });
  }

  get loadParams() {
    return [];
  }
}

export class ProfessionalPreOrdersCollection extends BaseCollection {
  get name() {
    return 'ProfessionalPreorders';
  }

  get url() {
    return conf.settings.PROFESSIONAL_PRE_ORDERS_URL;
  }

  get model() {
    return ProfessionalPreOrder;
  }

  filterByOrderGuidAndShopCode(orderGuid = null, shopCode = null) {
    if (orderGuid && shopCode) {
      let params = { orderGuid: orderGuid, shopCode: shopCode };
      return this.fetch({ data: params });
    }
    return [];
  }

  filterByProjectId(ProjectId = null) {
    if (ProjectId) {
      let params = { projectId: ProjectId };
      return this.fetch({ data: params });
    }
    return [];
  }

  filterByConfigurationIdAndProductType(configurationId = null, productType = null) {
    if (configurationId && productType) {
      let params = { configurationId: configurationId, productType: productType };
      return this.fetch({ data: params });
    }
    return [];
  }
}
