import React from 'react';
import classNames from 'classnames';
import { GraphicCollapsableWidget } from '../../core/components/graphicCollapsableWidget';
import { AeImage } from '../../core/components/AeImage';

export class BaseDebossingElementFormatWidget extends GraphicCollapsableWidget {
  get name() {
    throw new Error('name must be defined');
  }

  get label() {
    throw new Error('label must be defined');
  }

  get calculatePrice() {
    return false;
  }

  get scrollToWidgetIsActive() {
    return true;
  }

  /*
   * questa property serve per stabilire il campo dell'item che identifica il gruppo di grafiche svg
   * */
  get selectedItemProperty() {
    throw new Error('selectedItemProperty must be defined');
  }

  getSelectedItemValue(item) { //eslint-disable-line
    return item[this.selectedItemProperty];
  }

  setConfigurationSelectedItem(selectedItem, reloadView = true) { //eslint-disable-line
    throw new Error('setConfigurationSelectedItem must be defined');
  }

  itemClass(item) {
    if (this.props.selected) {
      return classNames('switchers-images__item', {
        active: this.props.selected && this.getSelectedItemValue(item) === this.props.selected
      });
    }

    return classNames('switchers-images__item', { active: item.isDefault });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items && this.props.selected !== nextProps.selected) {
      this.setState({ isChosen: false }); //need to reopen the item selector
      this.collectErrors(nextProps);
    }

    //need to set the new svg color & preview
    if (nextProps.items && this.props.items !== nextProps.items) {
      if (nextProps.items.length === 0) {
        this.props.delegate.clearDebossingPreviewSvg();
        return;
      }

      nextProps.items.forEach((item) => {
        if (
          item.isDefault ||
          (nextProps.selected && this.getSelectedItemValue(item) === nextProps.selected)
        ) {
          this.props.delegate.choseDebossingPreviewSvg(item.svgUrl);
          this.props.delegate.setDebossingPreviewSvg();
          this.setConfigurationSelectedItem(item, false);
        }
      });
    }
  }

  handleMouseOver(item) {
    this.props.delegate.setDebossingPreviewSvg(item.svgUrl);
  }

  handleMouseOut() {
    this.props.delegate.setDebossingPreviewSvg();
  }

  handleClick(item) {
    super.handleClick(item);
    this.props.delegate.choseDebossingPreviewSvg(item.svgUrl);
    if (!this.loading) {
      this.props.delegate.setDebossingPreviewSvg(item.svgUrl);
      this.props.delegate.choseDebossingPreviewSvgColor();
    }
  }

  propagateClick() {
    if (this.clickedItem) {
      this.setState({ isChosen: true });
      this.setConfigurationSelectedItem(this.clickedItem);
    }
  }

  renderSelectedItem() {
    let renderedItems = [];
    if (this.props.items && this.props.items.map) {
      renderedItems = this.props.items.map((item) => {
        if (this.getSelectedItemValue(item) === this.props.selected) {
          return (
            <div key={item.id} className="col-xs-6 col-sm-4 col-md-6 padding-color vcenter">
              <a className={this.itemClass(item)}>
                <span className="switchers-images__item__image">
                  <AeImage src={item.fileUrl} />
                </span>
              </a>
            </div>
          );
        }
      });
    }
    return renderedItems;
  }
}
