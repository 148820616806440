import React from 'react';

export const AeImage = (props) => {
  const { src, cropWidth, cropHeight, mode } = props;
  if (src) {
    const url = new URL(src);
    const search_params = url.searchParams;

    if (cropWidth) {
      search_params.set('width', cropWidth);
    }
    if (cropHeight) {
      search_params.set('height', cropHeight);
    }
    if (mode) {
      search_params.set('mode', mode);
    }

    url.search = search_params.toString();

    return <img src={url.toString()} />;
  }
  return null;
};
