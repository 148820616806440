import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AeIframe } from '@photosi/albumepoca-ui';

/*
 * Renders 3D player for Photographic cover book
 * */
export class PhotographicPreview3d extends React.Component {
  UNSAFE_componentWillReceiveProps() {
    let iframe = document.getElementById('player3dIframe');
    if (iframe && iframe.children[0].classList.length > 0) {
      if (
        iframe.children[0].contentDocument &&
        iframe.children[0].contentDocument.body.classList.contains('loading-content')
      ) {
        iframe.children[0].contentDocument.body.classList.remove('loading-content');
      }
    }
  }

  render() {
    const { url, loading } = this.props;

    let height = null;
    let width = '100%'; // to fix iframe resize
    if (this.props.height) {
      height = this.props.height + 'px';
    }

    let loadingClass = classNames({ 'loading-widget': loading });

    return (
      <div className="row cover-preview">
        <div className="col-xs-12">
          <div className={loadingClass}></div>
          <div className="iframe-3dcover-container" id="player3dIframe">
            {url ? (
              <AeIframe url={url} show={true} height={height} />
            ) : (
              <div className="loading-widget" style={{ width, height }} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

PhotographicPreview3d.propTypes = {
  url: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  loading: PropTypes.bool
};
