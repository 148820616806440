import { conf } from 'outlinejs';
import { BaseModel as BaseBackboneModel } from 'outlinejs-backbone-model/lib/models';

import OAuth2 from '../auth/oauth';
import { LanguageStorage } from './storage/index';

export function mergeBackboneOptions(options, newOptions) {
  Object.keys(newOptions).forEach(function (key) {
    if (!Object.prototype.hasOwnProperty.call(options, key)) {
      options[key] = newOptions[key];
    } else {
      Object.assign(options[key], newOptions[key]);
    }
  });
  return options;
}

export async function oauth2Sync(syncFunction, method, model, options = {}) {
  let token = OAuth2.loadToken();
  let currentLanguage = await LanguageStorage.getLanguage();
  options.headers = options.headers || {};
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  options.headers.Accept = `application/json; version=${conf.settings.API_VERSION}`;
  options.headers['Content-Type'] = 'application/json';
  options.headers['Accept-Language'] = currentLanguage;
  options.headers['x-api-key'] = conf.settings.X_API_KEY;

  return syncFunction(method, model, options);
}

export class BaseModel extends BaseBackboneModel {
  sync(method, model, options = {}) {
    return oauth2Sync(super.sync, method, model, options);
  }
}

export class ProfessionalPreOrder extends BaseModel {
  get urlRoot() {
    return conf.settings.PROFESSIONAL_PRE_ORDERS_URL;
  }

  get id() {
    return this.get('id');
  }

  get configurationUrl() {
    return this.get('configurationUrl');
  }

  get configurationId() {
    return this.get('configurationId');
  }

  get configurationGuid() {
    return this.get('configurationGuid');
  }

  get productType() {
    return this.get('productType');
  }

  get description() {
    return this.get('description');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  get projectId() {
    return this.get('projectId');
  }

  get serviceConfigurationTypeCode() {
    return this.get('serviceConfigurationTypeCode');
  }

  set serviceConfigurationTypeCode(value) {
    this.set('serviceConfigurationTypeCode', value);
  }

  fetch(options = {}) {
    let params = { data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE } };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }
}
