import { conf } from 'outlinejs';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { HttpRequestError } from './utils/errors';

export default class Logger {
  static initialize() {
    Sentry.init({
      environment: conf.settings.NODE_ENV,
      dsn: conf.settings.SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()]
    });
  }

  static addUserContext(user) {
    if (user) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        shopCode: user.shopCode,
        country: user.country,
        paymentType: user.paymentType
      });
      Sentry.setTags({ shopCode: user.shopCode });
    }
  }

  static removeUserContext() {
    Sentry.setUser(null);
  }

  static getErrorContext(errors) {
    let errorMessage = 'Generic error';
    let errorContext = {};

    try {
      if (errors.length === 1) {
        errorMessage = errors[0];
      } else if (errors.length > 1) {
        errorMessage = errors[0];
        errorContext = errors[1];
      }
    } catch (e) {
      throw new Error(e);
    }

    return { message: errorMessage, extra: errorContext };
  }

  static captureException(errors, level) {
    const { message, extra } = Logger.getErrorContext(errors);

    if (extra && extra.errorCode) {
      Sentry.setTags({ errorCode: extra.errorCode });
    }

    // overwrite error for HttpRequests
    if (extra && extra.error && typeof extra.error.code !== 'undefined') {
      if (extra.error.code === 0 || extra.error.code === '0') {
        // skip these errors
        return;
      }

      extra.originalMessage = message;

      let logMessage;
      if (extra.error && extra.error.errorText) {
        logMessage = extra.error.errorText;
      }
      if (extra.error && extra.error.errorJSON && extra.error.errorJSON.message) {
        logMessage = extra.error.errorJSON.message;
      }
      if (!logMessage) {
        logMessage = 'HTTP REQUEST GENERIC ERROR';
      }

      extra.error = new HttpRequestError(extra.error.code, logMessage);
    }

    Sentry.withScope((scope) => {
      scope.setLevel(level);
      if (extra.error) {
        Sentry.captureException(extra.error, { extra });
      } else {
        Sentry.captureMessage(message, { extra });
      }
    });
  }

  static error(...object) {
    Logger.captureException(object, Sentry.Severity.Error);
  }

  static warning(...object) {
    Logger.captureException(object, Sentry.Severity.Warning);
  }

  static info(...object) {
    console.info(object);
  }

  static log(...object) {
    console.log(object);
  }

  static debug(...object) {
    console.debug(object);
  }
}
