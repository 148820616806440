import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import { routing } from 'outlinejs';
import { controllers } from 'outlinejs';
import Cookies from 'js-cookie';
import OAuth2 from '../auth/oauth';

import Logger from '../core/logger';

import { LayoutView } from './views';
import { initStorage } from './storage/index';
import axios from 'axios';

/*
 * base class that provide common methods and for all AEcontrollers
 * */
export class BaseAEController extends controllers.BaseLayoutController {
  constructor(...props) {
    super(...props);
    this._unitOfMeasure = null;

    initStorage();
  }

  get funnelStep() {
    return 0;
  }

  get funnelSteps() {
    return [];
  }

  static get loginRequired() {
    return runtime.isClient;
  }

  get layoutView() {
    return LayoutView;
  }

  get unitOfMeasure() {
    if (!this._unitOfMeasure) {
      try {
        this._unitOfMeasure = Cookies.get(conf.settings.UNIT_OF_MEASURE_COOKIE_KEY);
      } catch (err) {
        console.error(err);
      }
    }
    if (!this._unitOfMeasure) {
      this.unitOfMeasure = conf.settings.DEFAULT_UNIT_OF_MEASURE;
    }
    return this._unitOfMeasure;
  }

  set unitOfMeasure(value) {
    try {
      this._unitOfMeasure = value;
      Cookies.set(conf.settings.UNIT_OF_MEASURE_COOKIE_KEY, value, {
        expires: 360,
        domain: conf.settings.UNIT_OF_MEASURE_COOKIE_DOMAIN
      });
    } catch (err) {
      console.error(err);
    }
  }

  isBetaTesterUser(shopCode) {
    return conf.settings.ENABLED_SHOPCODES.some((x) => x === shopCode);
  }

  updateUnityOfMeasure(value) {
    this.unitOfMeasure = value;
    this.render(this.context);
  }

  getDateNow() {
    let date = new Date();
    let str =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      '/' +
      date.getHours() +
      ':' +
      date.getMinutes() +
      ':' +
      date.getSeconds();

    return str;
  }

  scrollToTop() {
    if (runtime.isClient) {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      } catch (err) {
        console.error('Failed scrolling to node');
      }
    }
  }

  /*
   * start initial view rendering and replace content with loader
   * */
  startInitialRendering() {
    this.scrollToTop();

    this.initViewRendering = true;
    this.render(this.context);
  }

  /*
   * stop initial rendering
   * */
  stopInitialRendering() {
    this.initViewRendering = false;
    this.render(this.context);
  }

  /*
   * sets the status 'loading' to the entire view
   * */
  startLoadingPage() {
    this.loadingPage = true;
    this.render(this.context);
  }

  /*
   * remove the status 'loading' to the entire view
   * */
  stopLoadingPage() {
    this.loadingPage = false;
    this.render(this.context);
  }

  isUserLoggedIn() {
    return this.request && this.request.user;
  }

  /*
   * Initialize user, customerUser data
   * */
  async initContentProps() {
    // page is loading
    this.loadingPage = false;

    this.customerUser = {};
    this.user = {};

    if (runtime.isClient && this.request) {
      this.user = this.request.user;
      this.customerUser = this.request.customerUser;
      this.customerUserIsAuthorized = this.request.customerUserIsAuthorized;

      this.getProjectsQuantity();
    }
  }

  get context() {
    return {
      totalCartProjectsQuantity: this.totalCartProjectsQuantity,
      totalBucketProjectsQuantity: this.totalBucketProjectsQuantity
    };
  }

  async getProjectsQuantity() {
    if (this.isUserLoggedIn()) {
      this.getAllProjectsQuantity();
    }
  }

  async getProjectsQuantityByState(state) {
    try {
      const shopCode = this.customerUser.shopCode;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': conf.settings.X_API_KEY,
          Authorization: OAuth2.getBearerToken()
        }
      };

      let requestUrl = `${conf.settings.PROJECTS_QUANTITY_URL}?shopCode=${shopCode}&pageSize=0&softwareCode=PROFESSIONAL&currentPage=1`;
      if (state) {
        requestUrl += `&state=${state}`;
      }
      const response = await axios.get(requestUrl, options);
      return JSON.parse(response.headers['x-pagination'])['itemsCount'];
    } catch (error) {
      console.error(`failed getProjectsQuantityByState for state: ${state}`, error);
      return null;
    }
  }

  async getAllProjectsQuantity() {
    [this.totalCartProjectsQuantity, this.totalBucketProjectsQuantity] = await Promise.all([
      this.getProjectsQuantityByState('Cart'),
      this.getProjectsQuantityByState('Bucket')
    ]);
    this.render(this.context);
  }
}

/*
 * base class that provide common methods and for configuration controllers
 * (eventboook and packaging)
 * */
export class BaseConfiguratorController extends BaseAEController {
  setFilterViewValidity(isValid) {
    this.filtersViewValidity = isValid;
  }

  setPriceValidity(isValid) {
    this.priceValidity = isValid;
  }

  async checkWidgetErrors() {
    await this.setErrorsVisibility(true);
    if (!this.filtersViewValidity) {
      this.viewInstance.scrollToFirstWidgetError();
    }
    return this.filtersViewValidity;
  }

  async setErrorsVisibility(isVisible) {
    this.errorsVisibility = isVisible;
    this.render(this.context);
  }

  getErrorsVisibility() {
    return this.errorsVisibility;
  }

  setConfigurationItem(name, id, noReloadView, getPrice) {
    if (this.configuration[name] !== id) {
      this.configuration[name] = id;
      this.render(this.context);
      if (!noReloadView) {
        this.reloadView(getPrice);
      } else {
        this.getPriceForCurrentConfiguration();
      }
    }
  }

  setAccessoryConfigurationItem(accessoryConfiguration, name, id, noReloadView, getPrice) {
    if (accessoryConfiguration[name] !== id) {
      accessoryConfiguration[name] = id;
      this.render(this.context);
      if (!noReloadView) {
        this.reloadView(getPrice);
      } else {
        this.getPriceForCurrentConfiguration();
      }
    }
  }

  setConfigurationTexts(name, code, value) {
    if (!this.configuration[name]) {
      this.configuration[name] = {};
    }
    this.configuration[name][code] = value;
  }

  resetConfigurationTexts(name) {
    this.configuration[name] = {};
  }

  /* Read request query string params and redirect to correct view
   *
   * @example:
   * https://www.albumepoca.com/it/redirects/configurator_book_step_1?default=MATERIAL
   * */
  getConfiguratorRedirectUrl() {
    let state = { url: null, params: {} };

    if (this.request.query.default || this.request.query.cleanAll) {
      let urlParams = this.request.query.default
        ? { eventbookType: this.request.query.default }
        : null;
      state.url = 'eventbook:new';
      state.params = urlParams;
    }

    return state;
  }

  getCallToAction() {
    return this.i18n.gettext('Vai al passo successivo');
  }

  getTitle() {
    return this.i18n.gettext('Configura il prodotto');
  }

  initQueryParams() {
    /* get IDs from querystring params */
    this.configurationId = this.request.configurationId || null;
    this.packagingConfigurationId = this.request.packagingConfigurationId || null;
    this.standConfigurationId = this.request.standConfigurationId || null;

    this.preOrderId = this.request.preOrderId || null;
    this.packagingPreOrderId = this.request.packagingPreOrderId || null;
    this.standPreOrderId = this.request.standPreOrderId || null;

    this.projectId = this.request.projectId || null;
  }

  async initContentProps() {
    super.initContentProps();

    // init page rendering
    this.initViewRendering = true;

    // all filters values
    this.filters = [];

    // all filters loading value
    this.loadingCollection = {};

    // all filters with http errors
    this.filterErrorsCollection = {};

    // preview is loading
    this.loadingPreview = false;

    // true -> configuration is not ready
    this.productConfigurationIsLoading = true;

    // start with true validity
    this.filtersViewValidity = true;
    this.priceValidity = true;

    // error are hidden until explicit validation
    this.errorsVisibility = false;

    this.initQueryParams();
  }

  async getProduct(collection, params) {
    this.startCollectionLoading(collection.name);
    let items = null;
    let productItem = null;
    try {
      items = await collection.loadByParams(params);
      productItem = items.first();
    } catch (err) {
      Logger.error('Controller.getProduct', {
        error: err,
        params: params,
        items: items
      });
    } finally {
      this.stopCollectionLoading(collection.name);
    }
    return productItem;
  }

  async getPrice(collection, params) {
    let items = null;
    let price = null;
    let errorMsg = 'Controller:getPrice';
    let token = OAuth2.loadToken();
    if (this.isUserLoggedIn() && this.customerUserIsAuthorized) {
      if (!token) {
        this.redirectToLoginPage();
        return false;
      }
      if (!this.hasFilterErrorsCollection()) {
        this.startCollectionLoading(collection.name);
        try {
          items = await collection.loadByParams(params);
          price = items.first();
        } catch (err) {
          if (collection && collection.name) {
            errorMsg = `${collection.name} collection`;
          }
          Logger.error(errorMsg, {
            error: err,
            params: params,
            items: items,
            price: price
          });
        }
        this.stopCollectionLoading(collection.name);
      }
    }

    return price;
  }

  async setConfigurationPrice(priceItem) {
    this.product.price = priceItem;
    this.render(this.context);
  }

  async setProductConfigurationPrice(productPrice) {
    this.product.productPrice = productPrice;
    this.render(this.context);
  }

  async setProductConfigurationName(productName) {
    this.product.productName = productName;
    this.render(this.context);
  }

  async setConfigurationProduct(productItem) {
    this.product.productItem = productItem;
    this.setPreviewImage();
    this.render(this.context);
  }

  /** left preview handling functions section**/

  setPreviewImage(url) {
    this.product.previewUrl = url;
    this.render(this.context);
  }

  fixSvg(svg, limitRows) {
    this.fixSvgColor(svg);
    this.fixSvgTexts(svg, limitRows);

    /* save the svg xml element as ready to use text */
    var s = new XMLSerializer();
    return s.serializeToString(svg);
  }

  fixSvgColor(svg) {
    /*fix the svg color for all svg elements*/
    var allElements = svg.querySelectorAll('*');
    for (let i = 0; i < allElements.length; i++) {
      allElements[i].setAttribute('fill', this.product.svgColor);
    }
  }

  fixSvgTexts(svg, limitRows) {
    /*set the svg text and crop it to the relative svg limit*/
    for (let i = 1; i <= svg.getElementsByTagName('text').length; i++) {
      if (
        this.isSvgTextTypedByUser() ||
        (this.getPreviewTexts() && this.getPreviewTexts()[1]) ||
        (this.getPreviewTexts() && this.getPreviewTexts()[2])
      ) {
        /*
         * NOTA BENE
         * si assume come specifica che gli elementi con  id=testo1 ... testoN
         * rappresentino univocamente i testi dell'svg e la loro cardinalità
         */
        let elementID = '[id^="testo' + i + '"]';
        let text = this.getPreviewTexts()[i];
        let limit = this.product['previewTexLimit' + i];
        if (limitRows && limitRows[i]) {
          limit = limitRows[i];
        }
        if (text) {
          text = text.substring(0, limit);
        } else {
          text = '';
        }
        svg.querySelector(elementID).childNodes[0].nodeValue = text;
      }
    }
  }

  async setPreviewSvg(svgUrl, texts) {
    svgUrl = svgUrl || this.product.previewSvgUrl;
    if (svgUrl) {
      var request = axios;
      await request
        .get(svgUrl)
        .then((response) => {
          var data = response.data;
          var doc = new DOMParser().parseFromString(data, 'text/html');
          let svg = doc.getElementsByTagName('svg')[0];
          this.product.previewSvg = this.fixSvg(svg, texts);
          this.render(this.context);
        })
        .catch(function (error) {
          Logger.error('setPreviewSvg - Error on SVG file retrieve', {
            url: svgUrl,
            error: error
          });
        });
    }
  }

  clearPreviewSvg() {
    this.product.previewSvgUrl = null;
    this.product.previewSvg = null;
  }

  chosePreviewSvg(svgUrl) {
    this.product.previewSvgUrl = svgUrl;
  }

  setPreviewSvgColor(color) {
    if (color) {
      this.product.svgColor = color;
    } else {
      this.product.svgColor = this.product.chosenSvgColor;
    }
  }

  chosePreviewSvgColor(color) {
    color = color || this.product.svgColor;
    this.product.chosenSvgColor = this.product.svgColor = color;
  }

  chosePreviewTexLimit(rowLimit1, rowLimit2) {
    this.product.previewTexLimit1 = rowLimit1;
    this.product.previewTexLimit2 = rowLimit2;
  }

  chosePreviewSvgText() {
    this.product.SvgTextTyped = true;
  }

  // isSvgTextTypedByUser() {
  //   return this.product.SvgTextTyped;
  // }

  resetPreviewSvgColor() {
    this.product.svgColor = null;
    this.product.chosenSvgColor = null;
  }

  /** END OF left preview handling functions section**/

  /*
   * Handling debossing SVG application
   **/

  clearDebossingPreviewSvg() {
    this.product.previewDebossingSvgUrl = null;
    this.product.previewDebossingSvg = null;
  }

  choseDebossingPreviewSvg(svgUrl) {
    this.product.previewDebossingSvgUrl = svgUrl;
  }

  setDebossingPreviewSvgColor(color, isMetallic, colorCode) {
    if (color) {
      this.product.debossingSvgColor = color;
    } else {
      this.product.debossingSvgColor = this.product.chosenDebossingSvgColor;
    }
    //Hack for metallic colors
    if (isMetallic !== undefined) {
      this.product.debossingSvgMetallicColor = isMetallic;
    } else {
      this.product.debossingSvgMetallicColor = this.product.chosenDebossingSvgMetallicColor;
    }

    if (colorCode) {
      this.product.debossingSvgColorCode = colorCode;
    } else {
      this.product.debossingSvgColorCode = this.product.chosenDebossingSvgColorCode;
    }
  }

  choseDebossingPreviewSvgColor(color, isMetallic, colorCode) {
    color = color || this.product.debossingSvgColor;
    this.product.chosenDebossingSvgColor = this.product.debossingSvgColor = color;
    isMetallic = isMetallic || this.product.debossingSvgMetallicColor;
    this.product.chosenDebossingSvgMetallicColor = this.product.debossingSvgMetallicColor =
      isMetallic;
    colorCode = colorCode || this.product.debossingSvgColorCode;
    this.product.chosenDebossingSvgColorCode = this.product.debossingSvgColorCode = colorCode;
  }

  choseDebossingPreviewTexLimit(rowLimitMin, rowLimitMax) {
    this.product.previewTexLimitMin = rowLimitMin;
    this.product.previewTexLimitMax = rowLimitMax;
  }

  choseDebossingPreviewSvgText() {
    this.product.debossingSvgTextTyped = true;
  }

  // todo possiamo eliminarlo?
  isSvgTextTypedByUser() {
    if (this.configuration.decorationType === conf.settings.EVENT_BOOK_DECORATION_DEBOSSING_TYPE) {
      return this.product.debossingSvgTextTyped;
    }
    return this.product.SvgTextTyped;
  }

  resetPreviewDebossingSvgColor() {
    this.product.debossingSvgColor = null;
    this.product.chosenDebossingSvgColor = null;
    this.product.debossingSvgMetallicColor = null;
    this.product.chosenDebossingSvgMetallicColor = null;
    this.product.debossingSvgColorCode = null;
    this.product.chosenDebossingSvgColorCode = null;
  }

  fixDebossingSvgColor(svg) {
    /*fix the svg color for all svg elements*/
    var allElements = svg.querySelectorAll('*');
    //Hack for metallic colors
    if (this.product.debossingSvgMetallicColor && this.product.debossingSvgColorCode) {
      svg.insertAdjacentHTML(
        'afterbegin',
        conf.settings.METALLIC_FILTERS[this.product.debossingSvgColorCode]
      );
      for (let i = 0; i < allElements.length; i++) {
        allElements[i].setAttribute('fill', 'url(#metallic-vertical)');
        allElements[i].setAttribute(
          'filter',
          conf.settings.METALLIC_COLORS[`${this.product.debossingSvgColorCode}_SHADOW`]
        );
      }
    } else {
      for (let i = 0; i < allElements.length; i++) {
        allElements[i].setAttribute('fill', this.product.debossingSvgColor);
      }
    }
  }

  fixDebossingSvg(svg, limitRows) {
    this.fixDebossingSvgColor(svg);
    this.fixDebossingSvgTexts(svg, limitRows);

    /* save the svg xml element as ready to use text */
    var s = new XMLSerializer();
    return s.serializeToString(svg);
  }

  async setDebossingPreviewSvg(svgUrl, texts) {
    svgUrl = svgUrl || this.product.previewDebossingSvgUrl;
    if (svgUrl) {
      var request = axios;
      await request
        .get(svgUrl)
        .then((response) => {
          var data = response.data;
          var doc = new DOMParser().parseFromString(data, 'text/html');
          let svg = doc.getElementsByTagName('svg')[0];
          this.product.previewDebossingSvg = this.fixDebossingSvg(svg, texts);
          this.render(this.context);
        })
        .catch(function (error) {
          Logger.error('setPreviewSvg - Error on SVG file retrieve', {
            url: svgUrl,
            error: error
          });
        });
    }
  }

  fixDebossingSvgTexts(svg, texts) {
    if (
      this.isSvgTextTypedByUser() ||
      (this.getPreviewTexts() && Object.keys(this.getPreviewTexts()).length > 0)
    ) {
      let svgTextElements = svg.querySelectorAll('text');

      for (let i = 0; i <= svgTextElements.length; i++) {
        let svgTextElement = svgTextElements[i];

        if (svgTextElement) {
          let svgTextElementId = svgTextElement.getAttribute('id');

          if (svgTextElementId) {
            texts = this.getPreviewTexts()[svgTextElementId];
            let text = '';
            if (texts) {
              text = texts;
            }
            svgTextElement.childNodes[0].nodeValue = text;
          } else {
            console.error('SVG have text component without id');
          }
        }
      }
    }
  }

  /*
   * End debossing SVG functions
   **/
  fixScrollToNode(node) {
    function findElementPosition(element) {
      var curtop = 0;
      if (element.offsetParent) {
        do {
          curtop += element.offsetTop;
        } while (element = element.offsetParent); //eslint-disable-line
        return [curtop];
      }
    }

    try {
      const ELEMENT_OFFSET = 80;

      window.scroll({
        top: findElementPosition(node) - ELEMENT_OFFSET,
        left: 0,
        behavior: 'smooth'
      });
    } catch (err) {
      Logger.error('Failed scrolling to node', { error: err, node: node });
    }
  }

  /*
   * scroll the current view to the given widget
   */
  scrollToWidget(widgetNode) {
    this.fixScrollToNode(widgetNode);
  }

  /*
   * remove errors from widget
   */
  popWidgetError(widgetName) {
    let errors = this.state.errors;
    if (errors[widgetName]) {
      delete errors[widgetName];
    }
    this.setState({ errors: errors });
  }

  getLanguage() {
    let language = 'en';
    if (this.request && this.request.language) {
      language = this.request.language;
    }
    return language;
  }

  getParamsForCollection(collection) {
    let params = {};

    for (let value of collection.loadParams) {
      /*HACK: the page size could be implicit*/
      if (value === 'pageSize' && !this.configuration[value]) {
        params[value] = 50;
      } else if (value === 'per_page') {
        params[value] = 100;
      } else if (value === 'configurationId' || value === 'albumPackagingConfigurationId') {
        params[value] = this.product.productConfiguration.id;
      } else if (value === 'accessoryType') {
        params[value] = this.standAccessoryType;
      } else if (value === 'softwareCode') {
        params[value] = conf.settings.PROFESSIONAL_SOFTWARE_CODE;
      } else {
        params[value] = this.configuration[value];
      }

      /* PATCH API filters if query filter param does not exists */
      if (!params[value]) {
        params[value] = 'null';
      }
    }

    return params;
  }

  /*
   * load items of a ginven collection according to params then set the default item
   */
  async loadCollection(collection, type) {
    this.startCollectionLoading(collection.name);
    let params = this.getParamsForCollection(collection);
    if (type) {
      params.previousCoverColorMaterialType = type;
    }
    return collection
      .loadByParams(params)
      .then((items) => {
        this.filters[collection.name] = items;
        this.setDefaultValueByItems(items);
        this.stopCollectionLoading(collection.name);
      })
      .catch((err) => {
        this.addFilterToErrorCollection(collection.name);
        Logger.error('Loading collection error', {
          error: err,
          params: params,
          module: collection.model.modelName
        });
      });
  }

  /*
   * load items of a ginven collection according to params then set the default item
   */
  async loadAccessoryCollection(collection, accessoryConfiguration) {
    this.startCollectionLoading(collection.name);
    let params = this.getParamsForCollection(collection);

    return collection
      .loadByParams(params)
      .then((items) => {
        this.filters[collection.name] = items;
        this.setDefaultAccessoryValueByItems(items, accessoryConfiguration);
        this.stopCollectionLoading(collection.name);
      })
      .catch((err) => {
        this.addFilterToErrorCollection(collection.name);
        Logger.error('Loading collection error', {
          error: err,
          params: params,
          module: collection.model.modelName
        });
      });
  }

  async loadUvGraphicElementsCollection(collection, param) {
    this.startCollectionLoading(collection.name);
    let params = this.getParamsForCollection(collection);
    return collection
      .loadByParams(params)
      .then((items) => {
        this.filters[collection.name] = items;
        this.setDefaultValueByParam(items, param);
        this.stopCollectionLoading(collection.name);
      })
      .catch((err) => {
        this.addFilterToErrorCollection(collection.name);
        Logger.error('Loading collection error', {
          error: err,
          params: params,
          module: collection.model.modelName
        });
      });
  }

  startCollectionLoading(name) {
    this.loadingCollection[name] = true;
    this.render(this.context);
  }

  stopCollectionLoading(name) {
    if (this.loadingCollection[name]) {
      delete this.loadingCollection[name];
    }
    this.render(this.context);
  }

  addFilterToErrorCollection(name) {
    this.filterErrorsCollection[name] = true;
  }

  removeFilterFromErrorCollection(name) {
    if (this.filterErrorsCollection[name]) {
      delete this.filterErrorsCollection[name];
    }
  }

  resetFilterErrorsCollection() {
    this.filterErrorsCollection = {};
  }

  hasFilterErrorsCollection() {
    return this.filterErrorsCollection && Object.keys(this.filterErrorsCollection).length > 0;
  }

  /* extract (if possible) the default value from an item collection
   * and set it into configuration
   */
  setDefaultValueByItems(items) {
    let defaultValue = null;
    let isValidOption = false;
    let selected = this.configuration[items.model.modelName];
    /* se nella collection c'è bypassDefault non impostare il default */
    if (items.length === 1 && !items.bypassDefault) {
      defaultValue = items.first().id;
    } else {
      items.each(function (item, $selected, $isValidOption, $defaultValue) {  //eslint-disable-line
        isValidOption = isValidOption || item.id === selected;
        if (item.isDefault) {
          defaultValue = item.id;
        }
      });
    }

    if (!isValidOption && defaultValue) {
      this.configuration[items.model.modelName] = defaultValue;
    }
  }

  setDefaultAccessoryValueByItems(items, accessoryConfiguration) {
    let defaultValue = null;
    let isValidOption = false;
    let selected = accessoryConfiguration[items.model.modelName];

    /* se nella collection c'è bypassDefault non impostare il default */
    if (items.length === 1 && !items.bypassDefault) {
      defaultValue = items.first().id;
    } else {
      items.each(function (item, $selected, $isValidOption, $defaultValue) {  //eslint-disable-line
        isValidOption = isValidOption || item.id === selected;
        if (item.isDefault) {
          defaultValue = item.id;
        }
      });
    }
    if (!isValidOption && defaultValue) {
      accessoryConfiguration[items.model.modelName] = defaultValue;
    }
  }

  setDefaultValueByParam(items, params) {
    let uvGraphicSelected = false;
    let selectedGraphicName = this.configuration[params.uvGraphicElementSvgName];
    let configuration = this.configuration;
    let controller = this;

    items.each(function (item) {
      if (selectedGraphicName) {
        if (item.svgName === selectedGraphicName) {
          uvGraphicSelected = true;
          configuration[params.uvGraphicElementType] = item.id;
          controller.chosePreviewSvg(item.svgUrl);
          controller.setPreviewSvg(item.svgUrl);
        }
      }
    });

    if (!uvGraphicSelected) {
      items.each(function (item) {
        if (item.isDefault) {
          configuration[params.uvGraphicElementType] = item.id;
          configuration[params.uvGraphicElementSvgName] = item.svgName;
          controller.chosePreviewSvg(item.svgUrl);
          controller.setPreviewSvg(item.svgUrl);
        }
      });
    }
  }

  getdisabledWidgets() {
    return [];
  }

  redirectToLoginPage() {
    let nextUrlEncoded = encodeURIComponent(this.request.absoluteUrl);
    let loginUrl = routing.Utils.reverse('login');
    let loginUrlWithNextUrl = `${loginUrl}?next-url=${nextUrlEncoded}`;
    this.response.navigate(loginUrlWithNextUrl);
  }

  redirectToHomepage() {
    this.response.navigate(conf.settings.HOMEPAGE_BASE_URL);
  }
}
