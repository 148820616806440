import { conf, routing } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import url from 'url';
import Cookies from 'js-cookie';
import Logger from '../logger';
import { defaultCenter } from '../notification-center';
import { ProfessionalPreOrder } from '../models';
import { ProfessionalPreOrdersCollection } from '../managers';
import RetryOnError from 'retry-on-error-js';

export class GuiErrors {
  /* Modal EVENTS */
  static get modalErrorEvent() {
    return 'modalError';
  }

  static modalError(
    errorText,
    errorDetail,
    cancelLabel,
    onConfirmCallback = null,
    onCancelCallback = null
  ) {
    defaultCenter.emit(
      this.modalErrorEvent,
      errorText,
      errorDetail,
      cancelLabel,
      onConfirmCallback,
      onCancelCallback
    );
  }

  static get modalInfoEvent() {
    return 'modalInfo';
  }

  static modalInfo(content, title, cancelLabel, onConfirmCallback = null, onCancelCallback = null) {
    defaultCenter.emit(
      this.modalInfoEvent,
      content,
      title,
      cancelLabel,
      onConfirmCallback,
      onCancelCallback
    );
  }
}

export class GuiNotifications {
  static get notificationEvent() {
    return 'notificationInfo';
  }

  static notificationInfo(notificationText, notificationRange) {
    defaultCenter.emit(this.notificationEvent, notificationText, notificationRange);
  }

  static get modalWithHideButtonEvent() {
    return 'modalWithHideButton';
  }

  static modalWithHideButton(title, message, storageKey) {
    defaultCenter.emit(this.modalWithHideButtonEvent, title, message, storageKey);
  }

  static get modalConfirmActionEvent() {
    return 'modalConfirmAction';
  }

  static modalConfirmAction(
    content,
    title,
    confirmLabel,
    cancelLabel,
    onConfirmCallback = null,
    onCancelCallback = null
  ) {
    defaultCenter.emit(
      this.modalConfirmActionEvent,
      content,
      title,
      confirmLabel,
      cancelLabel,
      onConfirmCallback,
      onCancelCallback
    );
  }
}

export class GuiHttpErrors {
  static get configuration404Event() {
    return 'configuration404Event';
  }

  static configuration404(
    content = null,
    title = null,
    cancelLabel = null,
    onConfirmCallback = null,
    onCancelCallback = null
  ) {
    defaultCenter.emit(
      this.configuration404Event,
      content,
      title,
      cancelLabel,
      onConfirmCallback,
      onCancelCallback
    );
  }
}

/** ----------------- Preorder section ----------------- **/
export async function updateConfigurationsShopCode(
  coverConfiguration,
  packagingConfiguration,
  user
) {
  Logger.info('Update shopCode on configurations');

  coverConfiguration.shopCode = user.shopCode;
  packagingConfiguration.shopCode = user.shopCode;

  return await Promise.all([coverConfiguration.save(), packagingConfiguration.save()]);
}

export async function createPreOrder(
  user,
  type,
  configuration,
  projectId,
  configurationGuid = null
) {
  let preOrderData = {
    configurationUrl: configuration.getAbsoluteUrl(),
    configurationId: configuration.id,
    productType: configuration.productType,
    description: {},
    shopCode: user.shopCode,
    projectId: projectId || null,
    configurationGuid: configurationGuid
  };

  let preOrder = new ProfessionalPreOrder();
  preOrder = await preOrder.save(preOrderData);
  Logger.info('preOrderId:', preOrder.id);
  Logger.info('projectId:', preOrder.projectId);
  return preOrder;
}

export async function createProfessionalPreOrder(
  user,
  configuration,
  projectId,
  quantity,
  configurationGuid = null
) {
  let configurationId = configuration.numericId ? configuration.numericId : configuration.id;

  let preOrderData = {
    configurationId: configurationId,
    productType: configuration.productType,
    shopCode: user.shopCode,
    projectId: projectId || null,
    quantity: quantity,
    configurationGuid: configurationGuid
  };

  let preOrder = new ProfessionalPreOrder();
  preOrder = await preOrder.save(preOrderData);
  Logger.info('preOrderId:', preOrder.id);
  Logger.info('projectId:', preOrder.projectId);
  return preOrder;
}

export async function getPreOrder(configurationId, productType) {
  let preOrder;
  let preOrderList =
    await new ProfessionalPreOrdersCollection().filterByConfigurationIdAndProductType(
      configurationId,
      productType
    );
  if (preOrderList.length > 0) {
    preOrder = preOrderList.first();
  }
  return preOrder;
}

/** ----------------- END OF Preorder section ----------------- **/

/*
 * Update query string parameter
 * */
export function updateQueryStringParameter(uri, key, value) {
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

/** ----------------- Patch outlinejs navigate----------------- **/
export function mergeQueryParams(queryParams, newQueryParams) {
  Object.keys(newQueryParams).forEach((newQueryParamKey) => {
    let newQueryParamValue = newQueryParams[newQueryParamKey];
    // add to url only values not null
    if (newQueryParamValue) {
      // query params already exists with different value
      if (
        Object.prototype.hasOwnProperty.call(queryParams, newQueryParamKey) &&
        queryParams[newQueryParamKey] !== String(newQueryParamValue)
      ) {
        Logger.error(
          `Navigation Error - query param '${newQueryParamKey}' overwritten with different value`,
          {
            existingQueryParams: queryParams,
            newQueryParams: newQueryParams
          }
        );
      }

      queryParams[newQueryParamKey] = newQueryParamValue;
    }
  });

  return queryParams;
}

/**
 * Function used to navigate to an absolute url or an internal route
 * @param {object} request - OutlineJs request.
 * @param {object} response - OutlineJs response.
 * @param {string} to - destination url or state.
 * @param {object} stateParams - params used in state url definition.
 * @param {boolean} replaceHistory - if true replace previous history state.
 * @param {object} queryParams - query string params added to url.
 */
export function navigateTo(
  request,
  response,
  to,
  stateParams = {},
  replaceHistory = false,
  queryParams = null
) {
  let destinationUrl;
  let absoluteUrl = false;
  let addParams = stateParams && stateParams !== {};
  try {
    destinationUrl = routing.Utils.reverse(to, request, stateParams);
    addParams = false; //router already added query params
  } catch (ex) {
    destinationUrl = to;
  }

  if (runtime.isClient) {
    // add query params if necessary
    if (addParams) {
      for (let queryParam in stateParams) {
        destinationUrl = updateQueryStringParameter(
          destinationUrl,
          queryParam,
          stateParams[queryParam]
        );
      }
    }

    if (queryParams) {
      for (let queryParam in queryParams) {
        destinationUrl = updateQueryStringParameter(
          destinationUrl,
          queryParam,
          queryParams[queryParam]
        );
      }
    }

    // check if it's an absolute url
    if (url.parse(destinationUrl).protocol) {
      absoluteUrl = true;
    }

    if (conf.settings.ROUTING_USE_FRAGMENT) {
      let hasher = require('hasher');
      hasher.setHash(destinationUrl);
    } else {
      if (conf.settings.SERVER_SIDE_LINK_ONLY) {
        window.location.href = destinationUrl;
      } else {
        let history = require('html5-history-api');
        if (replaceHistory) {
          try {
            history.replaceState(null, null, destinationUrl);
          } catch (err) {
            window.location.replace(destinationUrl);
            return;
          }
        } else {
          history.pushState(null, null, destinationUrl);
        }

        if (absoluteUrl) {
          window.location.href = destinationUrl;
        } else {
          window.navigateEventEmitter.emit('navigate', destinationUrl);
        }
      }
    }
  } else {
    response.writeHead(302, { Location: destinationUrl });
    response.end();
  }
}

class HttpTimeoutErrorHandlerStrategy {
  static create() {
    return new HttpTimeoutErrorHandlerStrategy();
  }

  canCatch(error) {
    //eslint-disable-next-line
    if (error && Object.prototype.hasOwnProperty.call(error, "code") && [502, 503, 504].indexOf(error.code) !== -1) {
      return true;
    }
    return false;
  }
}

export async function promiseRetry(asyncFunction, errorMessage = 'Promise Retry', context = {}) {
  return await RetryOnError.runExponential(asyncFunction, context, {
    maxTries: 2,
    exponentialBase: 2,
    multiplier: 3,
    errorHandlerStrategy: new HttpTimeoutErrorHandlerStrategy(),
    logStrategy: function (e, { attempts, lastDelayTime }) {
      Logger.error(
        errorMessage,
        Object.assign(
          {
            errorName: e.name,
            errorStack: e.stack,
            errorMessage: e.message,
            errorCode: Object.prototype.hasOwnProperty.call(e, "code") ? e.code : '', //eslint-disable-line
            attempt: attempts,
            delay: lastDelayTime
          },
          context
        )
      );
    }
  });
}

/**
 * Returns true if impersonation is active
 * */
export function impersonationActive() {
  return !!Cookies.get(conf.settings.CUSTOMER_AUTH_COOKIE_KEY);
}
