import React from 'react';
import { components, routing } from 'outlinejs';
import Modal from 'react-modal';
import { AeIframe } from '@photosi/albumepoca-ui';
import { conf } from 'outlinejs';
import PropTypes from 'prop-types';

/*
 * Component that render a price and handle loading data
 */
export class Promo extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPromoPage: false
    };
    this.afterOpenModal = this.afterOpenModal.bind(this);
  }

  openPromotions() {
    const { isUserLoggedIn } = this.props;
    if (isUserLoggedIn) {
      this.setState({ showPromoPage: true });
    } else {
      const nextUrl = encodeURIComponent(window.location.href);
      let loginUrl = routing.Utils.reverse('login');
      let loginUrlWithNextUrl = `${loginUrl}?next-url=${nextUrl}`;
      return this.response.navigate(loginUrlWithNextUrl);
    }
  }

  closePromotions() {
    this.setState({ showPromoPage: false });
  }

  /* Add class to body */
  afterOpenModal() {
    window.document.body.classList.add('ReactModal__Body--open');
  }

  render() {
    if (this.props.productType && this.props.productType.toLowerCase().indexOf('packaging') > 0) {
      return null;
    }

    const { showPromoPage } = this.state;
    const modalStyle = {
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.30)',
        zIndex: 14
      },
      content: {
        zIndex: 14,
        outline: 'none',
        width: '98%',
        maxWidth: '1200px',
        margin: '1% auto',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        border: 'none',
        background: 'none',
        borderRadius: 0,
        padding: '0px',
        overflow: 'hidden'
      }
    };
    const modalHeight = parseInt((window.innerHeight * 97) / 100);
    const frameUrl = this.props.productType
      ? `${conf.settings.PROMO_URL[this.request.language]}?tag=${this.props.productType}`
      : null;

    return (
      <div className="row promo-block">
        <div className="promo-content">
          <a className="cta-primary-inverse" onClick={this.openPromotions.bind(this)}>
            {this.i18n.gettext('scopri le promozioni')}
          </a>
          <Modal
            contentLabel=""
            isOpen={showPromoPage}
            onAfterOpen={this.afterOpenModal}
            ariaHideApp={false}
            style={modalStyle}>
            <div className="row">
              <div className="col-xs-12">
                <div className="promo-modal-close">
                  <span onClick={this.closePromotions.bind(this)}>x</span>
                </div>
                <AeIframe url={frameUrl} height={modalHeight} show={true} />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

Promo.propTypes = {
  isUserLoggedIn: PropTypes.object,
  productType: PropTypes.string,
  delegate: PropTypes.object
};
