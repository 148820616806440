import React from 'react';
import classNames from 'classnames';

import BaseBootstrapWidget from './baseBootstrapWidget';

export class BaseSelectionWidgetFourColumns extends BaseBootstrapWidget {
  itemClasName() {
    return classNames('col-xs-6', 'col-sm-3', 'col-md-3', 'no-padding-col');
  }

  renderItems() {
    let items = [];
    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        let activeClass = item.id === this.props.selected ? 'active' : '';
        let itemClass = `btn-ae text-center ${activeClass}`;
        return (
          <div key={item.id} className={this.itemClasName()}>
            <div
              className={itemClass}
              onClick={this.handleClick.bind(this, item)}
              data-action={`set_${this.props.items.name}_${item.id}`}>
              {item.name}
            </div>
          </div>
        );
      });
    }
    return items;
  }

  renderContext() {
    return (
      <div className="product__formats" data-product-formats="">
        <div className="row">{this.renderItems()}</div>
        {this.renderErrors()}
      </div>
    );
  }
}
