import React from 'react';

import { components } from 'outlinejs';
import getSymbolFromCurrency from 'currency-symbol-map';

import classNames from 'classnames';

const ProductPrice = ({
  price,
  productIsOutOfStock,
  title,
  i18nInstance,
  priceclass,
  widgetPriceClass,
  showVat
}) => {
  return (
    <div>
      <h2 className="product__title">{title}</h2>
      {productIsOutOfStock && (
        <div className="item_out_of_stock"> {i18nInstance.gettext('product out of stock')}</div>
      )}
      <div className={widgetPriceClass}>
        {i18nInstance.gettext('questa configurazione')}:
        <span className={priceclass}>
          &nbsp;{price.price ? price.price.toFixed(2) : null}&nbsp;
          {getSymbolFromCurrency(price.currency)}&nbsp;
          {showVat ? i18nInstance.gettext('VAT exc.') : null}
        </span>
      </div>
    </div>
  );
};

const PackagingPrice = ({
  price,
  productTitle,
  productIsOutOfStock,
  productPrice,
  title,
  i18nInstance,
  priceclass,
  widgetPriceClass,
  showVat
}) => {
  return (
    <div>
      <div>
        <h2 className="product__title inline">{productTitle}:</h2>
        <span className={`additional-price ${priceclass}`}>
          &nbsp;{productPrice.price.toFixed(2)}&nbsp;
          {getSymbolFromCurrency(price.currency)}
        </span>
      </div>
      <div>
        <h2 className="product__title inline">{title}:</h2>
        <span className={`additional-price ${priceclass}`}>
          &nbsp;{price.price.toFixed(2)}&nbsp;
          {getSymbolFromCurrency(price.currency)}
        </span>
        {productIsOutOfStock && (
          <div className="item_out_of_stock"> {i18nInstance.gettext('product out of stock')}</div>
        )}
      </div>
      <div className={widgetPriceClass}>
        {i18nInstance.gettext('questa configurazione')}:
        <span className={priceclass}>
          &nbsp;{(price.price + productPrice.price).toFixed(2)}&nbsp;
          {getSymbolFromCurrency(price.currency)}&nbsp;
          {showVat ? i18nInstance.gettext('VAT exc.') : null}
        </span>
      </div>
    </div>
  );
};

/*
 *component that render a price and handle loading data
 */
export class Price extends components.BaseComponent {
  render() {
    const {
      isLoading,
      priceValidity,
      showWidgetPrice,
      productType,
      price,
      productTitle,
      productIsOutOfStock,
      productPrice,
      title
    } = this.props;

    const showVat =
      this.request.customerUser &&
      this.request.customerUser.countryIsoName !== 'CAN' &&
      this.request.customerUser.countryIsoName !== 'USA';

    const priceclass = classNames('productPrice', {
      productPriceRotated: !isLoading && priceValidity
    });

    const widgetPriceClass = classNames('product__cta__note hide', {
      'product__cta__note show': showWidgetPrice
    });

    const isPackagingPrice =
      productType &&
      productType.toLowerCase().indexOf('packaging') > 0 &&
      !(Object.entries(price).length === 0 && price.constructor === Object);

    return (
      <div className="col-xs-12 col-sm-7 col-md-7">
        {isPackagingPrice ? (
          <PackagingPrice
            price={price}
            productTitle={productTitle}
            productIsOutOfStock={productIsOutOfStock}
            productPrice={productPrice}
            title={title}
            i18nInstance={this.i18n}
            priceclass={priceclass}
            widgetPriceClass={widgetPriceClass}
            showVat={showVat}
          />
        ) : (
          <ProductPrice
            price={price}
            productIsOutOfStock={productIsOutOfStock}
            title={title}
            i18nInstance={this.i18n}
            priceclass={priceclass}
            widgetPriceClass={widgetPriceClass}
            showVat={showVat}
          />
        )}
      </div>
    );
  }
}
