import { runtime } from '@outlinejs/contexts';
import localForage from 'localforage';
import Logger from '../logger';
import { conf } from 'outlinejs';
import Cookies from 'js-cookie';

export function initStorage() {
  localForage.config();
}

export async function setLocalForage(key, value) {
  return localForage
    .setItem(key, value)
    .then(function () {
      return localForage.getItem(key);
    })
    .then(function (result) {
      return result;
    })
    .catch(function (error) {
      Logger.error('setLocalForage - lib error', {
        error: error,
        key: key,
        value: value
      });
    });
}

export async function getLocalForage(key) {
  return localForage
    .getItem(key)
    .then(function (result) {
      return result;
    })
    .catch(function (error) {
      Logger.error('getLocalForage - lib error', {
        error: error,
        key: key
      });
    });
}

export async function deleteLocalForage(key) {
  return localForage
    .removeItem(key)
    .then(function (object) {
      return object;
    })
    .catch(function (error) {
      Logger.error('deleteLocalForage - lib error', {
        error: error,
        key: key
      });
    });
}

class BaseStorage {
  static setLocalForage(key, value) {
    if (runtime.isClient) {
      return setLocalForage(key, value);
    }
  }

  static getLocalForage(key) {
    if (runtime.isClient) {
      return getLocalForage(key);
    }
  }

  static deleteLocalForage(key) {
    if (runtime.isClient) {
      return deleteLocalForage(key);
    }
  }
}

export class BaseConfiguratorStorage extends BaseStorage {
  static get preOrderKey() {
    return 'PreOrder';
  }

  static get configurationKey() {
    return 'Configuration';
  }

  static get configurationKeyPk() {
    return `${this.configurationKey}Pk`;
  }

  static get preConfigurationKey() {
    return 'PreConfiguration';
  }

  static get projectKey() {
    return 'Project';
  }
}

export class BaseConfiguratorProductStorage extends BaseConfiguratorStorage {
  // all SET functions ------
  // ------------------------
  static async setName(object) {
    let key = `${this.productPrefixKey}ProjectName`;
    this.setLocalForage(key, object);
  }

  static async setConfigurationPk(object) {
    let key = `${this.productPrefixKey}${this.configurationKeyPk}`;
    this.setLocalForage(key, object);
  }

  static async setPreConfiguration(object) {
    let key = `${this.productPrefixKey}${this.preConfigurationKey}`;
    this.setLocalForage(key, object);
  }

  static async setPreOrderPk(object) {
    let key = `${this.productPrefixKey}${this.preOrderKey}`;
    this.setLocalForage(key, object);
  }

  // all GET functions ------
  // ------------------------

  static async getName() {
    let key = `${this.productPrefixKey}ProjectName`;
    return this.getLocalForage(key);
  }

  static async getConfigurationPk() {
    let key = `${this.productPrefixKey}${this.configurationKeyPk}`;
    return this.getLocalForage(key);
  }

  // all CLEAN functions ----
  // ------------------------
  static async deleteConfigurationPk() {
    let key = `${this.productPrefixKey}${this.configurationKeyPk}`;
    return this.deleteLocalForage(key);
  }

  static async deletePreConfiguration() {
    let key = `${this.productPrefixKey}${this.preConfigurationKey}`;
    return this.deleteLocalForage(key);
  }

  static async deletePreOrderPk() {
    let key = `${this.productPrefixKey}${this.preOrderKey}`;
    return this.deleteLocalForage(key);
  }

  static async clean() {
    await Promise.all([
      this.deleteConfigurationPk(),
      this.deletePreOrderPk(),
      this.deletePreConfiguration()
    ]);
  }
}

export class ReferralStorage extends BaseStorage {
  // urls definition --------
  // ------------------------

  static getAlbumCatalogueUrl(language = conf.settings.DEFAULT_LANGUAGE) {
    let baseUrl = conf.settings.POW_WEBSITE_BASE_URL;
    let catalogueUrl;
    switch (language) {
      case 'it':
        catalogueUrl = `${baseUrl}it/catalogo/new-albums/`;
        break;
      case 'es':
        catalogueUrl = `${baseUrl}es/catalogo/new-albums/`;
        break;
      case 'en':
        catalogueUrl = `${baseUrl}en/catalogue/new-albums/`;
        break;
      default:
        catalogueUrl = `${baseUrl}en/catalogue/new-albums/`;
    }

    return catalogueUrl;
  }

  // end urls definition ----
  // ------------------------
}

export class LanguageStorage extends BaseStorage {
  static get cookieName() {
    return 'language';
  }

  /*
   * Get Cookie domain from URL and return only the last two parts.
   * example: '.dyn.dom', '.albumepoca.com'
   * */
  static get cookieDomain() {
    let parts = location.hostname.split('.');
    let localEnvironment =
      (parts.length === 4 && /^\d+$/.test(location.hostname.replace(/\./g, ''))) ||
      location.hostname === 'localhost';
    return localEnvironment ? location.hostname : '.' + parts.slice(-2).join('.');
  }

  static async setLanguage(language) {
    if (conf.settings.LANGUAGES.indexOf(language) > -1) {
      Cookies.set(this.cookieName, language, { expires: 365, domain: this.cookieDomain });
    } else {
      Logger.info(`La lingua "${language}" non è fra quelle disponibili di questa applicazione.`);
    }
  }

  static async getLanguage() {
    let result = Cookies.get(this.cookieName);
    if (result) {
      return result;
    }
    let browserLanguage = window.navigator.language.substring(0, 2);

    let lang =
      browserLanguage && conf.settings.LANGUAGES.indexOf(browserLanguage) > -1
        ? browserLanguage
        : conf.settings.DEFAULT_LANGUAGE;
    return lang;
  }
}

export class ProjectStorage extends BaseStorage {
  static get baseProjectKey() {
    return 'Project';
  }

  static get nameKey() {
    return `${this.baseProjectKey}Name`;
  }

  static get pkKey() {
    return `${this.baseProjectKey}Pk`;
  }

  static async setPk(pk) {
    let key = this.pkKey;
    this.setLocalForage(key, pk);
  }

  static async getPk() {
    let key = this.pkKey;
    return await this.getLocalForage(key);
  }

  static async setName(name) {
    let key = this.nameKey;
    this.setLocalForage(key, name);
  }

  static async getName() {
    let key = this.nameKey;
    return await this.getLocalForage(key);
  }

  static async deletePk() {
    let key = this.pkKey;
    return this.deleteLocalForage(key);
  }

  static async deleteName() {
    let key = this.nameKey;
    return this.deleteLocalForage(key);
  }

  static async clean(preserveName = false) {
    if (preserveName) {
      await Promise.all([this.deletePk()]);
    } else {
      await Promise.all([this.deletePk(), this.deleteName()]);
    }
  }
}

export class EventbookProjectStorage extends ProjectStorage {
  static get baseProjectKey() {
    return 'EventbookProject';
  }
}

export class AlbumProjectStorage extends ProjectStorage {
  static get baseProjectKey() {
    return 'AlbumProject';
  }
}

export class MattedPrintsProjectStorage extends ProjectStorage {
  static get baseProjectKey() {
    return 'MattedPrintsProject';
  }
}

export class UsbDriveProjectStorage extends ProjectStorage {
  static get baseProjectKey() {
    return 'UsbDriveProject';
  }
}

export class FrameProjectStorage extends ProjectStorage {
  static get baseProjectKey() {
    return 'FrameProject';
  }
}
